@import '../../_variables.scss';

.modal {
  position: absolute;
  width: 100%;
  top: 60px;
  right: 0;
  bottom: 0;
  left: 0;
	z-index: 10;
	overflow-y: scroll;
	visibility: hidden;
	opacity: 0;
	transition: all .2s ease-out;
}

.modal.show {
	visibility: visible;
	opacity: 1;
}

.modalBackground {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.modalDialog {
	position: relative;
	margin: 50px auto 5px;
	max-width: 95%;
	width: 500px;
	border-radius: 8px;
	box-shadow: 0px 0px 10px 0px #231818;
	background-color: white;
	padding: 15px 40px;
}

.closeButton {
	position: absolute;
	top: 6px;
	right: 6px;
	background-color: white;
	border: 1px solid $color-uma-light-blue;
}

.closeButton:hover {
	background-color: rgba(0, 174, 199, .1);
}

.closeButton:focus {
	background-color: white;
}

.closeButton::before, .closeButton::after {
	background-color: $color-uma-light-blue;
}

@media (max-width: 430px) {
	.modal {
  	top: 50px;
  }
	.modalDialog {
		padding: 10px 15px;
	}
}

/* Style Scrollbar (Webkit only and only for desktop) */
@media (min-width: 1024px) {
	.modal::-webkit-scrollbar-track
	{
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
		background-color: transparent;
		border-radius: 10px;
	}
	.modal::-webkit-scrollbar
	{
		width: 10px;
		border-radius: 10px;
		background-color: transparent;
	}
	.modal::-webkit-scrollbar-thumb
	{
		border-radius: 10px;
		border: 1px solid transparent;
		background-clip: padding-box;
		background-color: rgba(0, 46, 93, 0.8);
	}
}
@import '../../_variables.scss';

.notification {
  position: absolute;
  top: 63px;
  left: 50%;
  width: 450px;
  height: 60px;
	z-index: 50;
	background-color: rgba(241, 253, 255, .85);
	box-shadow: 0px 0px 7px 0px #231818;
	border-radius: 8px;
	padding: 5px 15px;
	overflow: hidden;
	display: flex;
	align-items: center;
	visibility: hidden;
	opacity: 0;
	cursor: pointer;
	transform-origin: top center;
	transform: scaleY(.1) translateX(-50%);
	transition: all .2s ease-out;
}

.notification.show {
	visibility: visible;
	opacity: 1;
	transform: scaleX(1) translateX(-50%);
}

.loginCircle {
	height: 20px;
	width: 20px;
	border-radius: 50%;
	margin-right: 15px;
	box-shadow: 0px 2px 2px 0px #555e63;
}

.loginText {
	height: 100%;
	margin: 0;
	color: $color-uma-light-blue;
	font-weight: bold;
}

.adminLogin {
	margin: 0;
	color: $color-uma-light-blue;
	font-weight: bold;
}

.successMessage {
	color: $color-uma-light-blue;
	display: flex;
	align-items: center;
}

.successIcon {
	fill: $color-uma-light-blue;
	margin-right: 7px;
}

.errorMessage {
	margin: 0;
	color: $color-danger;
	font-size: .95rem;	
}

.errorIcon {
	height: 35px;
	width: 35px;
	fill: $color-danger;
	margin-right: 10px;
}

@media (max-width: 640px) {
	.notification {
	  left: 10px;
	  max-width: calc(100% - 60px);
	  height: 50px;
	  font-size: .95rem;
	  transform: scaleY(.1);
	}
	.notification.show {
		visibility: visible;
		opacity: 1;
		transform: scaleX(1);
	}
}
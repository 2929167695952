@import '../../_variables.scss';

.header {
	display: flex;
	justify-content: center;
	align-items: center;
}

.headerCircle {
	height: 20px;
	width: 20px;
	border-radius: 50%;
	margin-right: 20px;
	box-shadow: 0px 2px 6px 0px #555e63;
}

.headerText {
	margin: 0;
	font-size: 1.5rem;
	color: $color-medium-blue;
}

.errorMessage {
	margin: 20px 20px -5px;
	color: $color-danger;
	font-size: .95rem;	
}

.formFields {
	margin-top: 20px;
	padding: 15px 20px;
	border-radius: 5px;
	background-color: $color-grey-background;
}

.input {
	font: inherit;
}

.input:hover {
	border-color: $color-form-light-blue;
}

.input:focus {
	border-color: $color-uma-light-blue;
	box-shadow: 0 0 0 0.125em rgba(0, 174, 199, 0.2);
}

.input::placeholder {
	color: $color-form-light-blue;
	font-size: 1rem;
}

.input.invalid {
	border-color: $color-danger;
}

.textarea {
	min-height: 75px;
}

.imagesField {
  background-color: white;
  border-color: #dbdbdb;
  color: #363636;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  padding: 10px 9px;
}

.imagesText {
	margin: 0;
	font-size: .85rem;
	color: #707070;
	line-height: 1.2;
	text-align: justify;
}

.images {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 10px;
}

.column {
	width: calc(50% - 5px);
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: $color-grey-background;
	padding: 5px 5px 2px 5px;
	border-radius: 4px;
}

.imageContainer {
	position: relative;
	padding-top: 66.66%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	outline: none;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center center;
	opacity: 1;
}

.miniImageIcon {
	position: absolute;
	top: 0;
	left: 0;
	height: 50%;
	width: 50%;
	fill: $color-form-light-blue;
	opacity: 0;
	transform-origin: top left;
	transform: scale(.1);
	transition: transform .1s ease-out, opacity .1s ease-out;	
}

.imageContainer:hover .miniImageIcon, .imageContainer:focus .miniImageIcon {
	opacity: 1;
	transform: scale(1);
}

.iconContainer {
	position: relative;
	padding-top: 66.66%;
	width: 100%;
	outline: none;
	cursor: pointer;
	background-color: white;
}

.imageIcon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 75%;
	width: 75%;
	fill: $color-form-light-blue;
	opacity: .8;
	transition: transform .1s ease-out, opacity .1s ease-out;
}

.iconContainer:hover .imageIcon, .iconContainer:focus .imageIcon {
	opacity: 1;
	transform: translate(-50%, -50%) scale(1.1);
}

.image {
	object-fit: cover;
}

.imageCaption {
	margin-top: 2px;
	font-size: .75rem;
	text-transform: uppercase;
	letter-spacing: 13px;
	margin-right: -13px;
	color: $color-form-light-blue;
}

.inputErrorMessage {
	margin: 0 0 5px;
	padding-left: 10px;
	color: $color-danger;
	font-size: .85rem;
}

.imagesErrorMessage {
	margin: 5px 0 0;
	padding-left: 10px;
	color: $color-danger;
	font-size: .85rem;
}

.submitButton {
	display: block;
	width: 40%;
	height: 40px;
	margin: 15px auto 0;
	background-color: $color-medium-blue;
	color: white;
	font-size: 1.1rem;
	border-radius: 7px;
}

.submitButton:focus {
 	box-shadow: none !important;
 	border-color: #dbdbdb;
 	color: white !important;	
 }

.submitButton:hover, .submitButton:active {
	opacity: .9;
	color: white;
}

.submitButton:disabled {
	background-color: $color-medium-blue;
	opacity: .4;
}

/* MOBILE */
@media (max-width: 530px) {
	.header {
		justify-content: flex-start;
		padding-left: 20px;
	}
	.headerCircle {
		margin-right: 10px;
	}
	.headerText {
		font-size: 1.3rem;
	}
	.formFields {
		margin-top: 15px;
	}
	.imageCaption {
		font-size: .65rem;
		letter-spacing: 5px;
		margin-right: -5px;
	}
}
@import url('https://fonts.googleapis.com/css?family=Rubik:400,500');

html, body, #root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 16px;
  /*font-family: 'Rubik', Helvetica Neue, Helvetica, sans-serif;*/
  font-family: Roboto, Helvetica, Arial, sans-serif;
  overflow-y: hidden !important;
}

* {
  box-sizing: border-box; 
}


/* BULMA MODIFIED VARIABLES (need to add them before the imports)*/
$gap: 32px;

/* BULMA IMPORTS */
@import "bulma/sass/utilities/_all.sass";
@import "bulma/sass/components/navbar.sass";
@import "bulma/sass/components/modal.sass";
@import "bulma/sass/elements/button.sass";
@import "bulma/sass/elements/form.sass";
@import "bulma/sass/elements/other.sass";


/* MAPBOX CSS ADJUSTMENTS */
.mapboxgl-ctrl-top-right {
  top: 80px;
}
.mapboxgl-ctrl-top-right .mapboxgl-ctrl.mapboxgl-ctrl-group {
  margin: 0 12px 0 0;
}

/* MOBILE */
@media (max-width: 640px) {
  /* MAPBOX CSS ADJUSTMENTS */
  .mapboxgl-ctrl-top-right .mapboxgl-ctrl.mapboxgl-ctrl-group {
    margin: 0 5px 0 0;
  }
  .mapboxgl-ctrl-bottom-left .mapboxgl-ctrl {
    margin: 0 0 3px 7px;
  }
  .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl.mapboxgl-ctrl-attrib.mapboxgl-compact {
    margin: 0 7px 3px 0;
  }
  .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-attrib.mapboxgl-compact::after {
    height: 20px;
    width: 20px;
  }
  .mapboxgl-ctrl-logo {
    width: 70px !important;
  }
}
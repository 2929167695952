@import '../../_variables.scss';

.header {
	margin: 0;
	text-align: center;
	font-size: 1.5rem;
	color: $color-medium-blue;
}

.authErrorMessage {
	margin: 10px 20px 0;
	color: $color-danger;
	font-size: .95rem;	
}

.resetText {
	margin: 15px 20px 0;
	font-size: .95rem;
}

.formFields {
	margin-top: 15px;
	padding: 15px 20px;
	border-radius: 5px;
	background-color: $color-grey-background;
}

.field:not(:last-child) {
	margin-bottom: 7px;
}

.input {
	font: inherit;
}

.input:hover {
	border-color: $color-form-light-blue;
}

.input:focus {
	border-color: $color-uma-light-blue;
	box-shadow: 0 0 0 0.125em rgba(0, 174, 199, 0.2);
}

.input::placeholder {
	color: $color-form-light-blue;
}

.input.invalid {
	border-color: $color-danger;
}

.inputIconContainer {
	padding-left: 5px;
}

.inputIcon {
	position: absolute;
	top: 21%;
	height: 50%;
	fill: $color-uma-light-blue;
}

.membersField {
	position: relative;
	margin-bottom: 25px !important;
}

.membersField input {
	padding-right: 25px;
}

.deleteIcon {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 3px;
	height: 18px;
	width: 18px;
	fill: $color-uma-light-blue;
	cursor: pointer;
}

.addButton {
	position: absolute;
	bottom: -19px;
	left: 5px;
	display: flex;
	align-items: center;
	cursor: pointer;
	outline: none;
}

.addIcon {
	height: 18px;
	width: 18px;
	fill: $color-uma-light-blue;
}

.addText {
	margin-left: 2px;
	font-size: .6rem;
	color: $color-uma-light-blue;
}

.addButton:focus .addText {
	text-decoration: underline;
}

.colorInput {
	height: 40px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 9px;
	font-weight: normal;
	color: $color-form-light-blue;
	background-color: white;
	border: 1px solid #dbdbdb;
	border-radius: 4px;
}

.colorInputLabel {
	width: 45%;
	width: 130px;
	padding-bottom: 4px;
}

.colors {
	width: calc(100% - 130px);
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.colorBox {
	height: 15px;
	width: 25%;
	border-radius: 5px;
	border: 2px solid #f1f1f1;
	cursor: pointer;
	transform-origin: center center;
	outline: none;
}

.colorBox.selected {
	height: 24px;
	box-shadow: 0px 0px 6px 0px #888b8d;
}

.colorBox:focus {
	box-shadow: 0px 0px 5px 0px #888b8d;
}

.colorBox.selected:focus {
	box-shadow: 0px 0px 8px 0px #888b8d;
}

@media (hover: hover) {
	.colorBox:hover {
		box-shadow: 0px 0px 7px 0px #888b8d;
	}
}

.conditions {
	margin-top: 15px;
	padding: 0 20px;
	font-size: .95rem;
}

.conditions label {
	display: flex;
	align-items: center;
	border: 1px solid transparent;
	border-radius: 4px;
	padding: 1px 0;
}

.conditions label.invalid {
	border-color: $color-danger;
}

.conditions label a {
	color: $color-medium-blue;
	text-decoration: none;
}

.conditions label a:hover {
	text-decoration: underline;
}

.inputErrorMessage {
	margin: 0 0 5px;
	padding-left: 10px;
	color: $color-danger;
	font-size: .85rem;
}

.submitButton {
	display: block;
	width: 50%;
	height: 40px;
	margin: 20px auto 0;
	background-color: $color-medium-blue;
	color: white;
	font-size: 1.1rem;
	border-radius: 7px;
}

.button.delete {
	background-color: $color-delete;
}

.submitButton:focus {
 	color: white !important;
 }

.submitButton:hover, .submitButton:active {
	opacity: .9;
	color: white;
}

.submitButton:disabled {
	background-color: $color-medium-blue;
	opacity: .4;
}

.link {
	margin: 15px 0 0;
	text-align: center;
	font-size: .95rem;
	color: $color-uma-light-blue;
	opacity: .7;
	cursor: pointer;
	outline: none;
}

.link:hover, .link:focus {
	text-decoration: underline;
	opacity: 1;
}

.authFormBlank {
	display: flex;
	justify-content: center;
	align-items: center;
}

.loadingColorsError {
	color: $color-danger;
	font-size: .95rem;	
}

/* EDIT & DELETE */
.passwordText {
	margin: 30px 0 5px 0;
}

.deleteQuestion {
	margin-top: 0;
	text-align: center;
	font-size: 1.1rem;
}

.deleteNote {
	text-align: center;
	font-size: .8rem;
	color: $color-danger;
}

.deleteNote p {
	margin: 0;
}

.deleteButtonsContainer {
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.deleteButton {
	display: block;
	width: 40%;
	height: 40px;
	margin: 15px auto 0;
	font-size: 1.1rem;
	border-radius: 7px;
}

.deleteButton.red {
	background-color: $color-delete;
	color: white;
}

.deleteButton:focus {
 	box-shadow: none !important;
 	border-color: #dbdbdb;
}

.deleteButton.red:focus {
 	color: white !important;	
}

.deleteButton.red:hover, .deleteButton.red:active {
	opacity: .9;
	color: white;
}

/* MOBILE */
@media (max-width: 430px) {
	.header {
		font-size: 1.3rem;
	}
	.resetText {
		margin: 15px 15px 0;
		font-size: .9rem;
	}
	.resetSuccessMessage {
		margin: 10px 15px 0;
		font-size: .85rem;
	}
	.successIcon {
		height: 20px;
		width: 20px;
	}
	.formFields {
		margin-top: 10px;
		padding: 15px 15px;
	}
	.input {
		font-size: .95rem;
	}
	.conditions {
		margin-top: 7px;
		padding-left: 15px;
		font-size: .8rem;
	}
	.submitButton {
		margin: 10px auto 0;
		font-size: 1rem;
	}
	.link {
		margin: 10px 0 0;
		font-size: .85rem;
	}
}
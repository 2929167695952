@import '../../_variables.scss';

.header {
	margin: 0 0 20px;
	text-align: center;
	font-size: 2rem;
}

.headerDark {
	color: $color-uma-dark-blue;
}

.headerLight {
	color: $color-uma-light-blue;
}

.paragraph1 {
	text-align: justify;
}

.paragraph2 {
	text-align: center;
}

.nameDark {
	color: $color-uma-dark-blue;
	font-size: 1.1rem;
	font-weight: bold;
}

.nameLight {
	color: $color-uma-light-blue;
	font-size: 1.1rem;
	font-weight: bold;
}

.iframeContainer {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;
	margin-top: 25px;
}

.iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.socialMediaHeader {
	margin: 25px 0 10px;
	text-align: center;
	font-size: 1.1rem;
	font-weight: bold;
	color: $color-uma-dark-blue;
}

.socialMediaIcons {
	width: 40%;
	margin: 0 auto 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.socialMediaIcon {
	fill: $color-uma-light-blue;
	cursor: pointer;
}

.socialMediaIcon:hover {
	fill: $color-uma-dark-blue;
}

.email {
	height: 22px;
	width: 22px;
}

.twitter {
	height: 22px;
	width: 22px;
}

.facebook {
	height: 20px;
	width: 20px;
}

.instagram {
	height: 22px;
	width: 22px;
}

.logosRow {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0 auto;
}

.logosRow.second {
	width: 80%;
	margin-top: 15px;
	margin-bottom: 20px;
}

.logosRow.third {
	margin-bottom: 10px;
}

.logoVicerrectorado {
	max-width: 40%;
}

.logoAndTech {
	max-width: 25%;
}

.logoRedCatedras {
	max-width: 25%;
}

.logoInteractividad {
	max-width: 40%;
}

.logoGeotecnologia {
	max-width: 40%;
}

.logoAyuntamiento {
	max-width: 25%;
}

.logoPromalaga {
	max-width: 25%;
}

.logoPolo {
	max-width: 25%;
}

.logoCartometrics {
	max-width: 25%;
}

@import '../../_variables.scss';

.navbar {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 60px;
	z-index: 20;
	min-height: auto;
	background-color: white;
	box-shadow: 0px 20px 15px rgba(25, 5, 5, 0.3);
}

.navbarBrand {
	min-height: auto;
	height: 100%;
	margin-left: 25px;
}

.logoContainer {
	padding: 0;
	margin: 0;
	height: 100%;
}

img.logo {
	max-height: none;
	height: 80%;
}

.navbarBurgerContainer {
	margin-left: auto;
	padding-right: 0
}

.navbarBurger, .navbarBurger:focus, .navbarBurger:active {
	height: 45px;
	width: 45px;
	border-radius: 50%;
	box-shadow: 0px 0px 7px 0px #231818 !important;
	border: none;
	outline: none;
	-webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
}

.navbarBurger span {
	height: 2px;
	color: $color-medium-blue;
}

.navbarBurger span:nth-child(1) {
	top: 12.5px;
	left: 13.5px;
	width: 18px;
	transform-origin: left top;
	transition: transform .2s ease-out;
}

.navbarBurger span:nth-child(2) {
	top: 21.5px;
	left: 10.5px;
	width: 24px;
	opacity: 1;
	transition: opacity .2s ease-out;
}

.navbarBurger span:nth-child(3) {
	top: 30.5px;
	left: 13.5px;
	width: 18px;
	transform-origin: left bottom;
	transition: transform .2s ease-out;
}

.navbarMenu {
	margin-right: 30px;
	padding: 0;
}

.navbarItem {
	margin-left: 20px;
	padding: 0;
}

.navbarItem:first-child {
	margin-left: 0;
}

.navbarButton {
	border: none;
	color: $color-uma-dark-blue;
	font-size: 1.05rem !important;
	font: inherit;
	text-decoration: none;
	padding-left: 0.75em;
	padding-right: 0.75em;
}

.navbarButton:focus {
	color: $color-uma-dark-blue;
	box-shadow: none !important;
}

.navbarButton:hover, .navbarButton:active {
	color: $color-uma-light-blue;
	box-shadow: none !important;
}

button.authButton {
	position: relative;
	height: 70%;
	width: 165px;
	border-radius: 7px;
	background-color: $color-uma-dark-blue;
	color: white;
	font-size: .9rem !important;
	padding: 0;
	margin-left: 10px;
}

.authButtonContent {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex !important;
	align-items: center;
	justify-content: center;
	border-radius: 7px;
}

.authButtonContent:hover {
	background-color: #114982;
}

button.authButton:focus {
	background-color: $color-uma-dark-blue;
	color: white;
}

button.authButton:hover, button.authButton:active {
	color: white;
}

.adminIcon {
	height: 23px;
	width: 23px;
	stroke: white;
	fill: $color-uma-light-blue;
	stroke-width: 40;
	margin-right: 15px;
}

.authIcon {
	height: 20px;
	width: 20px;
	stroke: white;
	stroke-width: 30;
	margin-right: 15px;
}

.authText {
	margin-right: 10px;
}

.arrowDownIcon {
	height: 30px;
	width: 30px;
	fill: white;
}

.dropdown {
	position: absolute;
	visibility: hidden;
	opacity: 0;
	top: calc(100% + 2px);
	right: 0;
	width: 100%;
	background-color: white;
	border: 1px solid $color-uma-dark-blue;
	border-top: none;
	border-bottom-right-radius: 7px;
	border-bottom-left-radius: 7px;
	color: $color-uma-dark-blue;
	font-size: 1rem;
	padding: 5px 0;
	transform-origin: top center;
	transform: scaleY(.1);
	transition: all .2s ease-out;
}

.dropdown.show {
	visibility: visible;
	opacity: 1;
	transform: scaleY(1);
}

.dropdown ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

.dropdown li {
	margin: 2px 0;
}

.dropdown li:hover {
	color: $color-uma-light-blue;
}

@media (max-width: 1023px) {
	.navbarBrand {
		margin-right: 25px;
	}
	.navbarBurger.close span:nth-child(1) {
		transform: rotate(45deg) scaleX(1.45);
	}
	.navbarBurger.close span:nth-child(2) {
		opacity: 0;
	}
	.navbarBurger.close span:nth-child(3) {
		transform: rotate(-45deg) scaleX(1.45);
	}
	.navbarMenu {
		margin-right: 0;
		display: block;
		visibility: hidden;
		opacity: 0;
		transform-origin: top center;
		transform: scaleY(.1);
		transition: all .2s ease-out;
	}
	.navbarMenu.shown {
		visibility: visible;
		opacity: 1;
		transform: scaleY(1);
		box-shadow: 0px 20px 10px rgba(25, 5, 5, 0.3);	
	}
	.navbarMenuItems {
		height: 200px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		padding: 10px 0;
	}
	.navbarItem {
		margin-left: 0;
	}
	button.authButton {
		height: 35px;
	}
}

@media (max-width: 500px) {
	.logoContainer {
		height: 100%;
		width: 70%;
	}
	img.logo {
		height: auto;
		width: 100%;
	}
}
@import url(https://fonts.googleapis.com/css?family=Rubik:400,500);
html, body, #root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 16px;
  /*font-family: 'Rubik', Helvetica Neue, Helvetica, sans-serif;*/
  font-family: Roboto, Helvetica, Arial, sans-serif;
  overflow-y: hidden !important; }

* {
  box-sizing: border-box; }

/* BULMA MODIFIED VARIABLES (need to add them before the imports)*/
/* BULMA IMPORTS */
@-webkit-keyframes spinAround {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg); } }
@keyframes spinAround {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg); } }

.modal-close, .delete, .button, .file {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.navbar-link:not(.is-arrowless)::after, .select:not(.is-multiple):not(.is-loading)::after {
  border: 3px solid transparent;
  border-radius: 2px;
  border-right: 0;
  border-top: 0;
  content: " ";
  display: block;
  height: 0.625em;
  margin-top: -0.4375em;
  pointer-events: none;
  position: absolute;
  top: 50%;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  -webkit-transform-origin: center;
          transform-origin: center;
  width: 0.625em; }

.block:not(:last-child), .highlight:not(:last-child) {
  margin-bottom: 1.5rem; }

.modal-close, .delete {
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: rgba(10, 10, 10, 0.2);
  border: none;
  border-radius: 290486px;
  cursor: pointer;
  pointer-events: auto;
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 0;
  height: 20px;
  max-height: 20px;
  max-width: 20px;
  min-height: 20px;
  min-width: 20px;
  outline: none;
  position: relative;
  vertical-align: top;
  width: 20px; }
  .modal-close::before, .delete::before, .modal-close::after, .delete::after {
    background-color: white;
    content: "";
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
            transform: translateX(-50%) translateY(-50%) rotate(45deg);
    -webkit-transform-origin: center center;
            transform-origin: center center; }
  .modal-close::before, .delete::before {
    height: 2px;
    width: 50%; }
  .modal-close::after, .delete::after {
    height: 50%;
    width: 2px; }
  .modal-close:hover, .delete:hover, .modal-close:focus, .delete:focus {
    background-color: rgba(10, 10, 10, 0.3); }
  .modal-close:active, .delete:active {
    background-color: rgba(10, 10, 10, 0.4); }
  .is-small.modal-close, .is-small.delete {
    height: 16px;
    max-height: 16px;
    max-width: 16px;
    min-height: 16px;
    min-width: 16px;
    width: 16px; }
  .is-medium.modal-close, .is-medium.delete {
    height: 24px;
    max-height: 24px;
    max-width: 24px;
    min-height: 24px;
    min-width: 24px;
    width: 24px; }
  .is-large.modal-close, .is-large.delete {
    height: 32px;
    max-height: 32px;
    max-width: 32px;
    min-height: 32px;
    min-width: 32px;
    width: 32px; }

.button.is-loading::after, .select.is-loading::after, .control.is-loading::after, .loader {
  -webkit-animation: spinAround 500ms infinite linear;
          animation: spinAround 500ms infinite linear;
  border: 2px solid #dbdbdb;
  border-radius: 290486px;
  border-right-color: transparent;
  border-top-color: transparent;
  content: "";
  display: block;
  height: 1em;
  position: relative;
  width: 1em; }

.modal, .modal-background {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0; }

.button, .input,
.textarea, .select select, .file-cta,
.file-name {
  -moz-appearance: none;
  -webkit-appearance: none;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: none;
  display: inline-flex;
  font-size: 1rem;
  height: 2.25em;
  justify-content: flex-start;
  line-height: 1.5;
  padding-bottom: calc(0.375em - 1px);
  padding-left: calc(0.625em - 1px);
  padding-right: calc(0.625em - 1px);
  padding-top: calc(0.375em - 1px);
  position: relative;
  vertical-align: top; }
  .button:focus, .input:focus,
  .textarea:focus, .select select:focus, .file-cta:focus,
  .file-name:focus, .is-focused.button, .is-focused.input,
  .is-focused.textarea, .select select.is-focused, .is-focused.file-cta,
  .is-focused.file-name, .button:active, .input:active,
  .textarea:active, .select select:active, .file-cta:active,
  .file-name:active, .is-active.button, .is-active.input,
  .is-active.textarea, .select select.is-active, .is-active.file-cta,
  .is-active.file-name {
    outline: none; }
  .button[disabled], .input[disabled],
  .textarea[disabled], .select select[disabled], .file-cta[disabled],
  .file-name[disabled],
  fieldset[disabled] .button,
  fieldset[disabled] .input,
  fieldset[disabled] .textarea,
  fieldset[disabled] .select select,
  .select fieldset[disabled] select,
  fieldset[disabled] .file-cta,
  fieldset[disabled] .file-name {
    cursor: not-allowed; }

.navbar {
  background-color: white;
  min-height: 3.25rem;
  position: relative;
  z-index: 30; }
  .navbar.is-white {
    background-color: white;
    color: #0a0a0a; }
    .navbar.is-white .navbar-brand > .navbar-item,
    .navbar.is-white .navbar-brand .navbar-link {
      color: #0a0a0a; }
    .navbar.is-white .navbar-brand > a.navbar-item:hover, .navbar.is-white .navbar-brand > a.navbar-item.is-active,
    .navbar.is-white .navbar-brand .navbar-link:hover,
    .navbar.is-white .navbar-brand .navbar-link.is-active {
      background-color: #f2f2f2;
      color: #0a0a0a; }
    .navbar.is-white .navbar-brand .navbar-link::after {
      border-color: #0a0a0a; }
    .navbar.is-white .navbar-burger {
      color: #0a0a0a; }
    @media screen and (min-width: 1024px) {
      .navbar.is-white .navbar-start > .navbar-item,
      .navbar.is-white .navbar-start .navbar-link,
      .navbar.is-white .navbar-end > .navbar-item,
      .navbar.is-white .navbar-end .navbar-link {
        color: #0a0a0a; }
      .navbar.is-white .navbar-start > a.navbar-item:hover, .navbar.is-white .navbar-start > a.navbar-item.is-active,
      .navbar.is-white .navbar-start .navbar-link:hover,
      .navbar.is-white .navbar-start .navbar-link.is-active,
      .navbar.is-white .navbar-end > a.navbar-item:hover,
      .navbar.is-white .navbar-end > a.navbar-item.is-active,
      .navbar.is-white .navbar-end .navbar-link:hover,
      .navbar.is-white .navbar-end .navbar-link.is-active {
        background-color: #f2f2f2;
        color: #0a0a0a; }
      .navbar.is-white .navbar-start .navbar-link::after,
      .navbar.is-white .navbar-end .navbar-link::after {
        border-color: #0a0a0a; }
      .navbar.is-white .navbar-item.has-dropdown:hover .navbar-link,
      .navbar.is-white .navbar-item.has-dropdown.is-active .navbar-link {
        background-color: #f2f2f2;
        color: #0a0a0a; }
      .navbar.is-white .navbar-dropdown a.navbar-item.is-active {
        background-color: white;
        color: #0a0a0a; } }
  .navbar.is-black {
    background-color: #0a0a0a;
    color: white; }
    .navbar.is-black .navbar-brand > .navbar-item,
    .navbar.is-black .navbar-brand .navbar-link {
      color: white; }
    .navbar.is-black .navbar-brand > a.navbar-item:hover, .navbar.is-black .navbar-brand > a.navbar-item.is-active,
    .navbar.is-black .navbar-brand .navbar-link:hover,
    .navbar.is-black .navbar-brand .navbar-link.is-active {
      background-color: black;
      color: white; }
    .navbar.is-black .navbar-brand .navbar-link::after {
      border-color: white; }
    .navbar.is-black .navbar-burger {
      color: white; }
    @media screen and (min-width: 1024px) {
      .navbar.is-black .navbar-start > .navbar-item,
      .navbar.is-black .navbar-start .navbar-link,
      .navbar.is-black .navbar-end > .navbar-item,
      .navbar.is-black .navbar-end .navbar-link {
        color: white; }
      .navbar.is-black .navbar-start > a.navbar-item:hover, .navbar.is-black .navbar-start > a.navbar-item.is-active,
      .navbar.is-black .navbar-start .navbar-link:hover,
      .navbar.is-black .navbar-start .navbar-link.is-active,
      .navbar.is-black .navbar-end > a.navbar-item:hover,
      .navbar.is-black .navbar-end > a.navbar-item.is-active,
      .navbar.is-black .navbar-end .navbar-link:hover,
      .navbar.is-black .navbar-end .navbar-link.is-active {
        background-color: black;
        color: white; }
      .navbar.is-black .navbar-start .navbar-link::after,
      .navbar.is-black .navbar-end .navbar-link::after {
        border-color: white; }
      .navbar.is-black .navbar-item.has-dropdown:hover .navbar-link,
      .navbar.is-black .navbar-item.has-dropdown.is-active .navbar-link {
        background-color: black;
        color: white; }
      .navbar.is-black .navbar-dropdown a.navbar-item.is-active {
        background-color: #0a0a0a;
        color: white; } }
  .navbar.is-light {
    background-color: whitesmoke;
    color: #363636; }
    .navbar.is-light .navbar-brand > .navbar-item,
    .navbar.is-light .navbar-brand .navbar-link {
      color: #363636; }
    .navbar.is-light .navbar-brand > a.navbar-item:hover, .navbar.is-light .navbar-brand > a.navbar-item.is-active,
    .navbar.is-light .navbar-brand .navbar-link:hover,
    .navbar.is-light .navbar-brand .navbar-link.is-active {
      background-color: #e8e8e8;
      color: #363636; }
    .navbar.is-light .navbar-brand .navbar-link::after {
      border-color: #363636; }
    .navbar.is-light .navbar-burger {
      color: #363636; }
    @media screen and (min-width: 1024px) {
      .navbar.is-light .navbar-start > .navbar-item,
      .navbar.is-light .navbar-start .navbar-link,
      .navbar.is-light .navbar-end > .navbar-item,
      .navbar.is-light .navbar-end .navbar-link {
        color: #363636; }
      .navbar.is-light .navbar-start > a.navbar-item:hover, .navbar.is-light .navbar-start > a.navbar-item.is-active,
      .navbar.is-light .navbar-start .navbar-link:hover,
      .navbar.is-light .navbar-start .navbar-link.is-active,
      .navbar.is-light .navbar-end > a.navbar-item:hover,
      .navbar.is-light .navbar-end > a.navbar-item.is-active,
      .navbar.is-light .navbar-end .navbar-link:hover,
      .navbar.is-light .navbar-end .navbar-link.is-active {
        background-color: #e8e8e8;
        color: #363636; }
      .navbar.is-light .navbar-start .navbar-link::after,
      .navbar.is-light .navbar-end .navbar-link::after {
        border-color: #363636; }
      .navbar.is-light .navbar-item.has-dropdown:hover .navbar-link,
      .navbar.is-light .navbar-item.has-dropdown.is-active .navbar-link {
        background-color: #e8e8e8;
        color: #363636; }
      .navbar.is-light .navbar-dropdown a.navbar-item.is-active {
        background-color: whitesmoke;
        color: #363636; } }
  .navbar.is-dark {
    background-color: #363636;
    color: whitesmoke; }
    .navbar.is-dark .navbar-brand > .navbar-item,
    .navbar.is-dark .navbar-brand .navbar-link {
      color: whitesmoke; }
    .navbar.is-dark .navbar-brand > a.navbar-item:hover, .navbar.is-dark .navbar-brand > a.navbar-item.is-active,
    .navbar.is-dark .navbar-brand .navbar-link:hover,
    .navbar.is-dark .navbar-brand .navbar-link.is-active {
      background-color: #292929;
      color: whitesmoke; }
    .navbar.is-dark .navbar-brand .navbar-link::after {
      border-color: whitesmoke; }
    .navbar.is-dark .navbar-burger {
      color: whitesmoke; }
    @media screen and (min-width: 1024px) {
      .navbar.is-dark .navbar-start > .navbar-item,
      .navbar.is-dark .navbar-start .navbar-link,
      .navbar.is-dark .navbar-end > .navbar-item,
      .navbar.is-dark .navbar-end .navbar-link {
        color: whitesmoke; }
      .navbar.is-dark .navbar-start > a.navbar-item:hover, .navbar.is-dark .navbar-start > a.navbar-item.is-active,
      .navbar.is-dark .navbar-start .navbar-link:hover,
      .navbar.is-dark .navbar-start .navbar-link.is-active,
      .navbar.is-dark .navbar-end > a.navbar-item:hover,
      .navbar.is-dark .navbar-end > a.navbar-item.is-active,
      .navbar.is-dark .navbar-end .navbar-link:hover,
      .navbar.is-dark .navbar-end .navbar-link.is-active {
        background-color: #292929;
        color: whitesmoke; }
      .navbar.is-dark .navbar-start .navbar-link::after,
      .navbar.is-dark .navbar-end .navbar-link::after {
        border-color: whitesmoke; }
      .navbar.is-dark .navbar-item.has-dropdown:hover .navbar-link,
      .navbar.is-dark .navbar-item.has-dropdown.is-active .navbar-link {
        background-color: #292929;
        color: whitesmoke; }
      .navbar.is-dark .navbar-dropdown a.navbar-item.is-active {
        background-color: #363636;
        color: whitesmoke; } }
  .navbar.is-primary {
    background-color: #00d1b2;
    color: #fff; }
    .navbar.is-primary .navbar-brand > .navbar-item,
    .navbar.is-primary .navbar-brand .navbar-link {
      color: #fff; }
    .navbar.is-primary .navbar-brand > a.navbar-item:hover, .navbar.is-primary .navbar-brand > a.navbar-item.is-active,
    .navbar.is-primary .navbar-brand .navbar-link:hover,
    .navbar.is-primary .navbar-brand .navbar-link.is-active {
      background-color: #00b89c;
      color: #fff; }
    .navbar.is-primary .navbar-brand .navbar-link::after {
      border-color: #fff; }
    .navbar.is-primary .navbar-burger {
      color: #fff; }
    @media screen and (min-width: 1024px) {
      .navbar.is-primary .navbar-start > .navbar-item,
      .navbar.is-primary .navbar-start .navbar-link,
      .navbar.is-primary .navbar-end > .navbar-item,
      .navbar.is-primary .navbar-end .navbar-link {
        color: #fff; }
      .navbar.is-primary .navbar-start > a.navbar-item:hover, .navbar.is-primary .navbar-start > a.navbar-item.is-active,
      .navbar.is-primary .navbar-start .navbar-link:hover,
      .navbar.is-primary .navbar-start .navbar-link.is-active,
      .navbar.is-primary .navbar-end > a.navbar-item:hover,
      .navbar.is-primary .navbar-end > a.navbar-item.is-active,
      .navbar.is-primary .navbar-end .navbar-link:hover,
      .navbar.is-primary .navbar-end .navbar-link.is-active {
        background-color: #00b89c;
        color: #fff; }
      .navbar.is-primary .navbar-start .navbar-link::after,
      .navbar.is-primary .navbar-end .navbar-link::after {
        border-color: #fff; }
      .navbar.is-primary .navbar-item.has-dropdown:hover .navbar-link,
      .navbar.is-primary .navbar-item.has-dropdown.is-active .navbar-link {
        background-color: #00b89c;
        color: #fff; }
      .navbar.is-primary .navbar-dropdown a.navbar-item.is-active {
        background-color: #00d1b2;
        color: #fff; } }
  .navbar.is-link {
    background-color: #3273dc;
    color: #fff; }
    .navbar.is-link .navbar-brand > .navbar-item,
    .navbar.is-link .navbar-brand .navbar-link {
      color: #fff; }
    .navbar.is-link .navbar-brand > a.navbar-item:hover, .navbar.is-link .navbar-brand > a.navbar-item.is-active,
    .navbar.is-link .navbar-brand .navbar-link:hover,
    .navbar.is-link .navbar-brand .navbar-link.is-active {
      background-color: #2366d1;
      color: #fff; }
    .navbar.is-link .navbar-brand .navbar-link::after {
      border-color: #fff; }
    .navbar.is-link .navbar-burger {
      color: #fff; }
    @media screen and (min-width: 1024px) {
      .navbar.is-link .navbar-start > .navbar-item,
      .navbar.is-link .navbar-start .navbar-link,
      .navbar.is-link .navbar-end > .navbar-item,
      .navbar.is-link .navbar-end .navbar-link {
        color: #fff; }
      .navbar.is-link .navbar-start > a.navbar-item:hover, .navbar.is-link .navbar-start > a.navbar-item.is-active,
      .navbar.is-link .navbar-start .navbar-link:hover,
      .navbar.is-link .navbar-start .navbar-link.is-active,
      .navbar.is-link .navbar-end > a.navbar-item:hover,
      .navbar.is-link .navbar-end > a.navbar-item.is-active,
      .navbar.is-link .navbar-end .navbar-link:hover,
      .navbar.is-link .navbar-end .navbar-link.is-active {
        background-color: #2366d1;
        color: #fff; }
      .navbar.is-link .navbar-start .navbar-link::after,
      .navbar.is-link .navbar-end .navbar-link::after {
        border-color: #fff; }
      .navbar.is-link .navbar-item.has-dropdown:hover .navbar-link,
      .navbar.is-link .navbar-item.has-dropdown.is-active .navbar-link {
        background-color: #2366d1;
        color: #fff; }
      .navbar.is-link .navbar-dropdown a.navbar-item.is-active {
        background-color: #3273dc;
        color: #fff; } }
  .navbar.is-info {
    background-color: #209cee;
    color: #fff; }
    .navbar.is-info .navbar-brand > .navbar-item,
    .navbar.is-info .navbar-brand .navbar-link {
      color: #fff; }
    .navbar.is-info .navbar-brand > a.navbar-item:hover, .navbar.is-info .navbar-brand > a.navbar-item.is-active,
    .navbar.is-info .navbar-brand .navbar-link:hover,
    .navbar.is-info .navbar-brand .navbar-link.is-active {
      background-color: #118fe4;
      color: #fff; }
    .navbar.is-info .navbar-brand .navbar-link::after {
      border-color: #fff; }
    .navbar.is-info .navbar-burger {
      color: #fff; }
    @media screen and (min-width: 1024px) {
      .navbar.is-info .navbar-start > .navbar-item,
      .navbar.is-info .navbar-start .navbar-link,
      .navbar.is-info .navbar-end > .navbar-item,
      .navbar.is-info .navbar-end .navbar-link {
        color: #fff; }
      .navbar.is-info .navbar-start > a.navbar-item:hover, .navbar.is-info .navbar-start > a.navbar-item.is-active,
      .navbar.is-info .navbar-start .navbar-link:hover,
      .navbar.is-info .navbar-start .navbar-link.is-active,
      .navbar.is-info .navbar-end > a.navbar-item:hover,
      .navbar.is-info .navbar-end > a.navbar-item.is-active,
      .navbar.is-info .navbar-end .navbar-link:hover,
      .navbar.is-info .navbar-end .navbar-link.is-active {
        background-color: #118fe4;
        color: #fff; }
      .navbar.is-info .navbar-start .navbar-link::after,
      .navbar.is-info .navbar-end .navbar-link::after {
        border-color: #fff; }
      .navbar.is-info .navbar-item.has-dropdown:hover .navbar-link,
      .navbar.is-info .navbar-item.has-dropdown.is-active .navbar-link {
        background-color: #118fe4;
        color: #fff; }
      .navbar.is-info .navbar-dropdown a.navbar-item.is-active {
        background-color: #209cee;
        color: #fff; } }
  .navbar.is-success {
    background-color: #23d160;
    color: #fff; }
    .navbar.is-success .navbar-brand > .navbar-item,
    .navbar.is-success .navbar-brand .navbar-link {
      color: #fff; }
    .navbar.is-success .navbar-brand > a.navbar-item:hover, .navbar.is-success .navbar-brand > a.navbar-item.is-active,
    .navbar.is-success .navbar-brand .navbar-link:hover,
    .navbar.is-success .navbar-brand .navbar-link.is-active {
      background-color: #20bc56;
      color: #fff; }
    .navbar.is-success .navbar-brand .navbar-link::after {
      border-color: #fff; }
    .navbar.is-success .navbar-burger {
      color: #fff; }
    @media screen and (min-width: 1024px) {
      .navbar.is-success .navbar-start > .navbar-item,
      .navbar.is-success .navbar-start .navbar-link,
      .navbar.is-success .navbar-end > .navbar-item,
      .navbar.is-success .navbar-end .navbar-link {
        color: #fff; }
      .navbar.is-success .navbar-start > a.navbar-item:hover, .navbar.is-success .navbar-start > a.navbar-item.is-active,
      .navbar.is-success .navbar-start .navbar-link:hover,
      .navbar.is-success .navbar-start .navbar-link.is-active,
      .navbar.is-success .navbar-end > a.navbar-item:hover,
      .navbar.is-success .navbar-end > a.navbar-item.is-active,
      .navbar.is-success .navbar-end .navbar-link:hover,
      .navbar.is-success .navbar-end .navbar-link.is-active {
        background-color: #20bc56;
        color: #fff; }
      .navbar.is-success .navbar-start .navbar-link::after,
      .navbar.is-success .navbar-end .navbar-link::after {
        border-color: #fff; }
      .navbar.is-success .navbar-item.has-dropdown:hover .navbar-link,
      .navbar.is-success .navbar-item.has-dropdown.is-active .navbar-link {
        background-color: #20bc56;
        color: #fff; }
      .navbar.is-success .navbar-dropdown a.navbar-item.is-active {
        background-color: #23d160;
        color: #fff; } }
  .navbar.is-warning {
    background-color: #ffdd57;
    color: rgba(0, 0, 0, 0.7); }
    .navbar.is-warning .navbar-brand > .navbar-item,
    .navbar.is-warning .navbar-brand .navbar-link {
      color: rgba(0, 0, 0, 0.7); }
    .navbar.is-warning .navbar-brand > a.navbar-item:hover, .navbar.is-warning .navbar-brand > a.navbar-item.is-active,
    .navbar.is-warning .navbar-brand .navbar-link:hover,
    .navbar.is-warning .navbar-brand .navbar-link.is-active {
      background-color: #ffd83d;
      color: rgba(0, 0, 0, 0.7); }
    .navbar.is-warning .navbar-brand .navbar-link::after {
      border-color: rgba(0, 0, 0, 0.7); }
    .navbar.is-warning .navbar-burger {
      color: rgba(0, 0, 0, 0.7); }
    @media screen and (min-width: 1024px) {
      .navbar.is-warning .navbar-start > .navbar-item,
      .navbar.is-warning .navbar-start .navbar-link,
      .navbar.is-warning .navbar-end > .navbar-item,
      .navbar.is-warning .navbar-end .navbar-link {
        color: rgba(0, 0, 0, 0.7); }
      .navbar.is-warning .navbar-start > a.navbar-item:hover, .navbar.is-warning .navbar-start > a.navbar-item.is-active,
      .navbar.is-warning .navbar-start .navbar-link:hover,
      .navbar.is-warning .navbar-start .navbar-link.is-active,
      .navbar.is-warning .navbar-end > a.navbar-item:hover,
      .navbar.is-warning .navbar-end > a.navbar-item.is-active,
      .navbar.is-warning .navbar-end .navbar-link:hover,
      .navbar.is-warning .navbar-end .navbar-link.is-active {
        background-color: #ffd83d;
        color: rgba(0, 0, 0, 0.7); }
      .navbar.is-warning .navbar-start .navbar-link::after,
      .navbar.is-warning .navbar-end .navbar-link::after {
        border-color: rgba(0, 0, 0, 0.7); }
      .navbar.is-warning .navbar-item.has-dropdown:hover .navbar-link,
      .navbar.is-warning .navbar-item.has-dropdown.is-active .navbar-link {
        background-color: #ffd83d;
        color: rgba(0, 0, 0, 0.7); }
      .navbar.is-warning .navbar-dropdown a.navbar-item.is-active {
        background-color: #ffdd57;
        color: rgba(0, 0, 0, 0.7); } }
  .navbar.is-danger {
    background-color: #ff3860;
    color: #fff; }
    .navbar.is-danger .navbar-brand > .navbar-item,
    .navbar.is-danger .navbar-brand .navbar-link {
      color: #fff; }
    .navbar.is-danger .navbar-brand > a.navbar-item:hover, .navbar.is-danger .navbar-brand > a.navbar-item.is-active,
    .navbar.is-danger .navbar-brand .navbar-link:hover,
    .navbar.is-danger .navbar-brand .navbar-link.is-active {
      background-color: #ff1f4b;
      color: #fff; }
    .navbar.is-danger .navbar-brand .navbar-link::after {
      border-color: #fff; }
    .navbar.is-danger .navbar-burger {
      color: #fff; }
    @media screen and (min-width: 1024px) {
      .navbar.is-danger .navbar-start > .navbar-item,
      .navbar.is-danger .navbar-start .navbar-link,
      .navbar.is-danger .navbar-end > .navbar-item,
      .navbar.is-danger .navbar-end .navbar-link {
        color: #fff; }
      .navbar.is-danger .navbar-start > a.navbar-item:hover, .navbar.is-danger .navbar-start > a.navbar-item.is-active,
      .navbar.is-danger .navbar-start .navbar-link:hover,
      .navbar.is-danger .navbar-start .navbar-link.is-active,
      .navbar.is-danger .navbar-end > a.navbar-item:hover,
      .navbar.is-danger .navbar-end > a.navbar-item.is-active,
      .navbar.is-danger .navbar-end .navbar-link:hover,
      .navbar.is-danger .navbar-end .navbar-link.is-active {
        background-color: #ff1f4b;
        color: #fff; }
      .navbar.is-danger .navbar-start .navbar-link::after,
      .navbar.is-danger .navbar-end .navbar-link::after {
        border-color: #fff; }
      .navbar.is-danger .navbar-item.has-dropdown:hover .navbar-link,
      .navbar.is-danger .navbar-item.has-dropdown.is-active .navbar-link {
        background-color: #ff1f4b;
        color: #fff; }
      .navbar.is-danger .navbar-dropdown a.navbar-item.is-active {
        background-color: #ff3860;
        color: #fff; } }
  .navbar > .container {
    align-items: stretch;
    display: flex;
    min-height: 3.25rem;
    width: 100%; }
  .navbar.has-shadow {
    box-shadow: 0 2px 0 0 whitesmoke; }
  .navbar.is-fixed-bottom, .navbar.is-fixed-top {
    left: 0;
    position: fixed;
    right: 0;
    z-index: 30; }
  .navbar.is-fixed-bottom {
    bottom: 0; }
    .navbar.is-fixed-bottom.has-shadow {
      box-shadow: 0 -2px 0 0 whitesmoke; }
  .navbar.is-fixed-top {
    top: 0; }

html.has-navbar-fixed-top,
body.has-navbar-fixed-top {
  padding-top: 3.25rem; }

html.has-navbar-fixed-bottom,
body.has-navbar-fixed-bottom {
  padding-bottom: 3.25rem; }

.navbar-brand,
.navbar-tabs {
  align-items: stretch;
  display: flex;
  flex-shrink: 0;
  min-height: 3.25rem; }

.navbar-brand a.navbar-item:hover {
  background-color: transparent; }

.navbar-tabs {
  -webkit-overflow-scrolling: touch;
  max-width: 100vw;
  overflow-x: auto;
  overflow-y: hidden; }

.navbar-burger {
  color: #4a4a4a;
  cursor: pointer;
  display: block;
  height: 3.25rem;
  position: relative;
  width: 3.25rem;
  margin-left: auto; }
  .navbar-burger span {
    background-color: currentColor;
    display: block;
    height: 1px;
    left: calc(50% - 8px);
    position: absolute;
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transition-duration: 86ms;
            transition-duration: 86ms;
    -webkit-transition-property: background-color, opacity, -webkit-transform;
    transition-property: background-color, opacity, -webkit-transform;
    transition-property: background-color, opacity, transform;
    transition-property: background-color, opacity, transform, -webkit-transform;
    -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
    width: 16px; }
    .navbar-burger span:nth-child(1) {
      top: calc(50% - 6px); }
    .navbar-burger span:nth-child(2) {
      top: calc(50% - 1px); }
    .navbar-burger span:nth-child(3) {
      top: calc(50% + 4px); }
  .navbar-burger:hover {
    background-color: rgba(0, 0, 0, 0.05); }
  .navbar-burger.is-active span:nth-child(1) {
    -webkit-transform: translateY(5px) rotate(45deg);
            transform: translateY(5px) rotate(45deg); }
  .navbar-burger.is-active span:nth-child(2) {
    opacity: 0; }
  .navbar-burger.is-active span:nth-child(3) {
    -webkit-transform: translateY(-5px) rotate(-45deg);
            transform: translateY(-5px) rotate(-45deg); }

.navbar-menu {
  display: none; }

.navbar-item,
.navbar-link {
  color: #4a4a4a;
  display: block;
  line-height: 1.5;
  padding: 0.5rem 0.75rem;
  position: relative; }
  .navbar-item .icon:only-child,
  .navbar-link .icon:only-child {
    margin-left: -0.25rem;
    margin-right: -0.25rem; }

a.navbar-item,
.navbar-link {
  cursor: pointer; }
  a.navbar-item:hover, a.navbar-item.is-active,
  .navbar-link:hover,
  .navbar-link.is-active {
    background-color: #fafafa;
    color: #3273dc; }

.navbar-item {
  display: block;
  flex-grow: 0;
  flex-shrink: 0; }
  .navbar-item img {
    max-height: 1.75rem; }
  .navbar-item.has-dropdown {
    padding: 0; }
  .navbar-item.is-expanded {
    flex-grow: 1;
    flex-shrink: 1; }
  .navbar-item.is-tab {
    border-bottom: 1px solid transparent;
    min-height: 3.25rem;
    padding-bottom: calc(0.5rem - 1px); }
    .navbar-item.is-tab:hover {
      background-color: transparent;
      border-bottom-color: #3273dc; }
    .navbar-item.is-tab.is-active {
      background-color: transparent;
      border-bottom-color: #3273dc;
      border-bottom-style: solid;
      border-bottom-width: 3px;
      color: #3273dc;
      padding-bottom: calc(0.5rem - 3px); }

.navbar-content {
  flex-grow: 1;
  flex-shrink: 1; }

.navbar-link:not(.is-arrowless) {
  padding-right: 2.5em; }
  .navbar-link:not(.is-arrowless)::after {
    border-color: #3273dc;
    margin-top: -0.375em;
    right: 1.125em; }

.navbar-dropdown {
  font-size: 0.875rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem; }
  .navbar-dropdown .navbar-item {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }

.navbar-divider {
  background-color: whitesmoke;
  border: none;
  display: none;
  height: 2px;
  margin: 0.5rem 0; }

@media screen and (max-width: 1023px) {
  .navbar > .container {
    display: block; }
  .navbar-brand .navbar-item,
  .navbar-tabs .navbar-item {
    align-items: center;
    display: flex; }
  .navbar-link::after {
    display: none; }
  .navbar-menu {
    background-color: white;
    box-shadow: 0 8px 16px rgba(10, 10, 10, 0.1);
    padding: 0.5rem 0; }
    .navbar-menu.is-active {
      display: block; }
  .navbar.is-fixed-bottom-touch, .navbar.is-fixed-top-touch {
    left: 0;
    position: fixed;
    right: 0;
    z-index: 30; }
  .navbar.is-fixed-bottom-touch {
    bottom: 0; }
    .navbar.is-fixed-bottom-touch.has-shadow {
      box-shadow: 0 -2px 3px rgba(10, 10, 10, 0.1); }
  .navbar.is-fixed-top-touch {
    top: 0; }
  .navbar.is-fixed-top .navbar-menu, .navbar.is-fixed-top-touch .navbar-menu {
    -webkit-overflow-scrolling: touch;
    max-height: calc(100vh - 3.25rem);
    overflow: auto; }
  html.has-navbar-fixed-top-touch,
  body.has-navbar-fixed-top-touch {
    padding-top: 3.25rem; }
  html.has-navbar-fixed-bottom-touch,
  body.has-navbar-fixed-bottom-touch {
    padding-bottom: 3.25rem; } }

@media screen and (min-width: 1024px) {
  .navbar,
  .navbar-menu,
  .navbar-start,
  .navbar-end {
    align-items: stretch;
    display: flex; }
  .navbar {
    min-height: 3.25rem; }
    .navbar.is-spaced {
      padding: 1rem 2rem; }
      .navbar.is-spaced .navbar-start,
      .navbar.is-spaced .navbar-end {
        align-items: center; }
      .navbar.is-spaced a.navbar-item,
      .navbar.is-spaced .navbar-link {
        border-radius: 4px; }
    .navbar.is-transparent a.navbar-item:hover, .navbar.is-transparent a.navbar-item.is-active,
    .navbar.is-transparent .navbar-link:hover,
    .navbar.is-transparent .navbar-link.is-active {
      background-color: transparent !important; }
    .navbar.is-transparent .navbar-item.has-dropdown.is-active .navbar-link, .navbar.is-transparent .navbar-item.has-dropdown.is-hoverable:hover .navbar-link {
      background-color: transparent !important; }
    .navbar.is-transparent .navbar-dropdown a.navbar-item:hover {
      background-color: whitesmoke;
      color: #0a0a0a; }
    .navbar.is-transparent .navbar-dropdown a.navbar-item.is-active {
      background-color: whitesmoke;
      color: #3273dc; }
  .navbar-burger {
    display: none; }
  .navbar-item,
  .navbar-link {
    align-items: center;
    display: flex; }
  .navbar-item {
    display: flex; }
    .navbar-item.has-dropdown {
      align-items: stretch; }
    .navbar-item.has-dropdown-up .navbar-link::after {
      -webkit-transform: rotate(135deg) translate(0.25em, -0.25em);
              transform: rotate(135deg) translate(0.25em, -0.25em); }
    .navbar-item.has-dropdown-up .navbar-dropdown {
      border-bottom: 2px solid #dbdbdb;
      border-radius: 6px 6px 0 0;
      border-top: none;
      bottom: 100%;
      box-shadow: 0 -8px 8px rgba(10, 10, 10, 0.1);
      top: auto; }
    .navbar-item.is-active .navbar-dropdown, .navbar-item.is-hoverable:hover .navbar-dropdown {
      display: block; }
      .navbar.is-spaced .navbar-item.is-active .navbar-dropdown, .navbar-item.is-active .navbar-dropdown.is-boxed, .navbar.is-spaced .navbar-item.is-hoverable:hover .navbar-dropdown, .navbar-item.is-hoverable:hover .navbar-dropdown.is-boxed {
        opacity: 1;
        pointer-events: auto;
        -webkit-transform: translateY(0);
                transform: translateY(0); }
  .navbar-menu {
    flex-grow: 1;
    flex-shrink: 0; }
  .navbar-start {
    justify-content: flex-start;
    margin-right: auto; }
  .navbar-end {
    justify-content: flex-end;
    margin-left: auto; }
  .navbar-dropdown {
    background-color: white;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top: 2px solid #dbdbdb;
    box-shadow: 0 8px 8px rgba(10, 10, 10, 0.1);
    display: none;
    font-size: 0.875rem;
    left: 0;
    min-width: 100%;
    position: absolute;
    top: 100%;
    z-index: 20; }
    .navbar-dropdown .navbar-item {
      padding: 0.375rem 1rem;
      white-space: nowrap; }
    .navbar-dropdown a.navbar-item {
      padding-right: 3rem; }
      .navbar-dropdown a.navbar-item:hover {
        background-color: whitesmoke;
        color: #0a0a0a; }
      .navbar-dropdown a.navbar-item.is-active {
        background-color: whitesmoke;
        color: #3273dc; }
    .navbar.is-spaced .navbar-dropdown, .navbar-dropdown.is-boxed {
      border-radius: 6px;
      border-top: none;
      box-shadow: 0 8px 8px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
      display: block;
      opacity: 0;
      pointer-events: none;
      top: calc(100% + (-4px));
      -webkit-transform: translateY(-5px);
              transform: translateY(-5px);
      -webkit-transition-duration: 86ms;
              transition-duration: 86ms;
      -webkit-transition-property: opacity, -webkit-transform;
      transition-property: opacity, -webkit-transform;
      transition-property: opacity, transform;
      transition-property: opacity, transform, -webkit-transform; }
    .navbar-dropdown.is-right {
      left: auto;
      right: 0; }
  .navbar-divider {
    display: block; }
  .navbar > .container .navbar-brand,
  .container > .navbar .navbar-brand {
    margin-left: -.75rem; }
  .navbar > .container .navbar-menu,
  .container > .navbar .navbar-menu {
    margin-right: -.75rem; }
  .navbar.is-fixed-bottom-desktop, .navbar.is-fixed-top-desktop {
    left: 0;
    position: fixed;
    right: 0;
    z-index: 30; }
  .navbar.is-fixed-bottom-desktop {
    bottom: 0; }
    .navbar.is-fixed-bottom-desktop.has-shadow {
      box-shadow: 0 -2px 3px rgba(10, 10, 10, 0.1); }
  .navbar.is-fixed-top-desktop {
    top: 0; }
  html.has-navbar-fixed-top-desktop,
  body.has-navbar-fixed-top-desktop {
    padding-top: 3.25rem; }
  html.has-navbar-fixed-bottom-desktop,
  body.has-navbar-fixed-bottom-desktop {
    padding-bottom: 3.25rem; }
  html.has-spaced-navbar-fixed-top,
  body.has-spaced-navbar-fixed-top {
    padding-top: 5.25rem; }
  html.has-spaced-navbar-fixed-bottom,
  body.has-spaced-navbar-fixed-bottom {
    padding-bottom: 5.25rem; }
  a.navbar-item.is-active,
  .navbar-link.is-active {
    color: #0a0a0a; }
  a.navbar-item.is-active:not(:hover),
  .navbar-link.is-active:not(:hover) {
    background-color: transparent; }
  .navbar-item.has-dropdown:hover .navbar-link, .navbar-item.has-dropdown.is-active .navbar-link {
    background-color: #fafafa; } }

.hero.is-fullheight-with-navbar {
  min-height: calc(100vh - 3.25rem); }

.modal {
  align-items: center;
  display: none;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  z-index: 40; }
  .modal.is-active {
    display: flex; }

.modal-background {
  background-color: rgba(10, 10, 10, 0.86); }

.modal-content,
.modal-card {
  margin: 0 20px;
  max-height: calc(100vh - 160px);
  overflow: auto;
  position: relative;
  width: 100%; }
  @media screen and (min-width: 769px), print {
    .modal-content,
    .modal-card {
      margin: 0 auto;
      max-height: calc(100vh - 40px);
      width: 640px; } }

.modal-close {
  background: none;
  height: 40px;
  position: fixed;
  right: 20px;
  top: 20px;
  width: 40px; }

.modal-card {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 40px);
  overflow: hidden;
  -ms-overflow-y: visible; }

.modal-card-head,
.modal-card-foot {
  align-items: center;
  background-color: whitesmoke;
  display: flex;
  flex-shrink: 0;
  justify-content: flex-start;
  padding: 20px;
  position: relative; }

.modal-card-head {
  border-bottom: 1px solid #dbdbdb;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px; }

.modal-card-title {
  color: #363636;
  flex-grow: 1;
  flex-shrink: 0;
  font-size: 1.5rem;
  line-height: 1; }

.modal-card-foot {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top: 1px solid #dbdbdb; }
  .modal-card-foot .button:not(:last-child) {
    margin-right: 10px; }

.modal-card-body {
  -webkit-overflow-scrolling: touch;
  background-color: white;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
  padding: 20px; }

.button {
  background-color: white;
  border-color: #dbdbdb;
  border-width: 1px;
  color: #363636;
  cursor: pointer;
  justify-content: center;
  padding-bottom: calc(0.375em - 1px);
  padding-left: 0.75em;
  padding-right: 0.75em;
  padding-top: calc(0.375em - 1px);
  text-align: center;
  white-space: nowrap; }
  .button strong {
    color: inherit; }
  .button .icon, .button .icon.is-small, .button .icon.is-medium, .button .icon.is-large {
    height: 1.5em;
    width: 1.5em; }
  .button .icon:first-child:not(:last-child) {
    margin-left: calc(-0.375em - 1px);
    margin-right: 0.1875em; }
  .button .icon:last-child:not(:first-child) {
    margin-left: 0.1875em;
    margin-right: calc(-0.375em - 1px); }
  .button .icon:first-child:last-child {
    margin-left: calc(-0.375em - 1px);
    margin-right: calc(-0.375em - 1px); }
  .button:hover, .button.is-hovered {
    border-color: #b5b5b5;
    color: #363636; }
  .button:focus, .button.is-focused {
    border-color: #3273dc;
    color: #363636; }
    .button:focus:not(:active), .button.is-focused:not(:active) {
      box-shadow: 0 0 0 0.125em rgba(50, 115, 220, 0.25); }
  .button:active, .button.is-active {
    border-color: #4a4a4a;
    color: #363636; }
  .button.is-text {
    background-color: transparent;
    border-color: transparent;
    color: #4a4a4a;
    text-decoration: underline; }
    .button.is-text:hover, .button.is-text.is-hovered, .button.is-text:focus, .button.is-text.is-focused {
      background-color: whitesmoke;
      color: #363636; }
    .button.is-text:active, .button.is-text.is-active {
      background-color: #e8e8e8;
      color: #363636; }
    .button.is-text[disabled],
    fieldset[disabled] .button.is-text {
      background-color: transparent;
      border-color: transparent;
      box-shadow: none; }
  .button.is-white {
    background-color: white;
    border-color: transparent;
    color: #0a0a0a; }
    .button.is-white:hover, .button.is-white.is-hovered {
      background-color: #f9f9f9;
      border-color: transparent;
      color: #0a0a0a; }
    .button.is-white:focus, .button.is-white.is-focused {
      border-color: transparent;
      color: #0a0a0a; }
      .button.is-white:focus:not(:active), .button.is-white.is-focused:not(:active) {
        box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 0.25); }
    .button.is-white:active, .button.is-white.is-active {
      background-color: #f2f2f2;
      border-color: transparent;
      color: #0a0a0a; }
    .button.is-white[disabled],
    fieldset[disabled] .button.is-white {
      background-color: white;
      border-color: transparent;
      box-shadow: none; }
    .button.is-white.is-inverted {
      background-color: #0a0a0a;
      color: white; }
      .button.is-white.is-inverted:hover {
        background-color: black; }
      .button.is-white.is-inverted[disabled],
      fieldset[disabled] .button.is-white.is-inverted {
        background-color: #0a0a0a;
        border-color: transparent;
        box-shadow: none;
        color: white; }
    .button.is-white.is-loading::after {
      border-color: transparent transparent #0a0a0a #0a0a0a !important; }
    .button.is-white.is-outlined {
      background-color: transparent;
      border-color: white;
      color: white; }
      .button.is-white.is-outlined:hover, .button.is-white.is-outlined:focus {
        background-color: white;
        border-color: white;
        color: #0a0a0a; }
      .button.is-white.is-outlined.is-loading::after {
        border-color: transparent transparent white white !important; }
      .button.is-white.is-outlined[disabled],
      fieldset[disabled] .button.is-white.is-outlined {
        background-color: transparent;
        border-color: white;
        box-shadow: none;
        color: white; }
    .button.is-white.is-inverted.is-outlined {
      background-color: transparent;
      border-color: #0a0a0a;
      color: #0a0a0a; }
      .button.is-white.is-inverted.is-outlined:hover, .button.is-white.is-inverted.is-outlined:focus {
        background-color: #0a0a0a;
        color: white; }
      .button.is-white.is-inverted.is-outlined[disabled],
      fieldset[disabled] .button.is-white.is-inverted.is-outlined {
        background-color: transparent;
        border-color: #0a0a0a;
        box-shadow: none;
        color: #0a0a0a; }
  .button.is-black {
    background-color: #0a0a0a;
    border-color: transparent;
    color: white; }
    .button.is-black:hover, .button.is-black.is-hovered {
      background-color: #040404;
      border-color: transparent;
      color: white; }
    .button.is-black:focus, .button.is-black.is-focused {
      border-color: transparent;
      color: white; }
      .button.is-black:focus:not(:active), .button.is-black.is-focused:not(:active) {
        box-shadow: 0 0 0 0.125em rgba(10, 10, 10, 0.25); }
    .button.is-black:active, .button.is-black.is-active {
      background-color: black;
      border-color: transparent;
      color: white; }
    .button.is-black[disabled],
    fieldset[disabled] .button.is-black {
      background-color: #0a0a0a;
      border-color: transparent;
      box-shadow: none; }
    .button.is-black.is-inverted {
      background-color: white;
      color: #0a0a0a; }
      .button.is-black.is-inverted:hover {
        background-color: #f2f2f2; }
      .button.is-black.is-inverted[disabled],
      fieldset[disabled] .button.is-black.is-inverted {
        background-color: white;
        border-color: transparent;
        box-shadow: none;
        color: #0a0a0a; }
    .button.is-black.is-loading::after {
      border-color: transparent transparent white white !important; }
    .button.is-black.is-outlined {
      background-color: transparent;
      border-color: #0a0a0a;
      color: #0a0a0a; }
      .button.is-black.is-outlined:hover, .button.is-black.is-outlined:focus {
        background-color: #0a0a0a;
        border-color: #0a0a0a;
        color: white; }
      .button.is-black.is-outlined.is-loading::after {
        border-color: transparent transparent #0a0a0a #0a0a0a !important; }
      .button.is-black.is-outlined[disabled],
      fieldset[disabled] .button.is-black.is-outlined {
        background-color: transparent;
        border-color: #0a0a0a;
        box-shadow: none;
        color: #0a0a0a; }
    .button.is-black.is-inverted.is-outlined {
      background-color: transparent;
      border-color: white;
      color: white; }
      .button.is-black.is-inverted.is-outlined:hover, .button.is-black.is-inverted.is-outlined:focus {
        background-color: white;
        color: #0a0a0a; }
      .button.is-black.is-inverted.is-outlined[disabled],
      fieldset[disabled] .button.is-black.is-inverted.is-outlined {
        background-color: transparent;
        border-color: white;
        box-shadow: none;
        color: white; }
  .button.is-light {
    background-color: whitesmoke;
    border-color: transparent;
    color: #363636; }
    .button.is-light:hover, .button.is-light.is-hovered {
      background-color: #eeeeee;
      border-color: transparent;
      color: #363636; }
    .button.is-light:focus, .button.is-light.is-focused {
      border-color: transparent;
      color: #363636; }
      .button.is-light:focus:not(:active), .button.is-light.is-focused:not(:active) {
        box-shadow: 0 0 0 0.125em rgba(245, 245, 245, 0.25); }
    .button.is-light:active, .button.is-light.is-active {
      background-color: #e8e8e8;
      border-color: transparent;
      color: #363636; }
    .button.is-light[disabled],
    fieldset[disabled] .button.is-light {
      background-color: whitesmoke;
      border-color: transparent;
      box-shadow: none; }
    .button.is-light.is-inverted {
      background-color: #363636;
      color: whitesmoke; }
      .button.is-light.is-inverted:hover {
        background-color: #292929; }
      .button.is-light.is-inverted[disabled],
      fieldset[disabled] .button.is-light.is-inverted {
        background-color: #363636;
        border-color: transparent;
        box-shadow: none;
        color: whitesmoke; }
    .button.is-light.is-loading::after {
      border-color: transparent transparent #363636 #363636 !important; }
    .button.is-light.is-outlined {
      background-color: transparent;
      border-color: whitesmoke;
      color: whitesmoke; }
      .button.is-light.is-outlined:hover, .button.is-light.is-outlined:focus {
        background-color: whitesmoke;
        border-color: whitesmoke;
        color: #363636; }
      .button.is-light.is-outlined.is-loading::after {
        border-color: transparent transparent whitesmoke whitesmoke !important; }
      .button.is-light.is-outlined[disabled],
      fieldset[disabled] .button.is-light.is-outlined {
        background-color: transparent;
        border-color: whitesmoke;
        box-shadow: none;
        color: whitesmoke; }
    .button.is-light.is-inverted.is-outlined {
      background-color: transparent;
      border-color: #363636;
      color: #363636; }
      .button.is-light.is-inverted.is-outlined:hover, .button.is-light.is-inverted.is-outlined:focus {
        background-color: #363636;
        color: whitesmoke; }
      .button.is-light.is-inverted.is-outlined[disabled],
      fieldset[disabled] .button.is-light.is-inverted.is-outlined {
        background-color: transparent;
        border-color: #363636;
        box-shadow: none;
        color: #363636; }
  .button.is-dark {
    background-color: #363636;
    border-color: transparent;
    color: whitesmoke; }
    .button.is-dark:hover, .button.is-dark.is-hovered {
      background-color: #2f2f2f;
      border-color: transparent;
      color: whitesmoke; }
    .button.is-dark:focus, .button.is-dark.is-focused {
      border-color: transparent;
      color: whitesmoke; }
      .button.is-dark:focus:not(:active), .button.is-dark.is-focused:not(:active) {
        box-shadow: 0 0 0 0.125em rgba(54, 54, 54, 0.25); }
    .button.is-dark:active, .button.is-dark.is-active {
      background-color: #292929;
      border-color: transparent;
      color: whitesmoke; }
    .button.is-dark[disabled],
    fieldset[disabled] .button.is-dark {
      background-color: #363636;
      border-color: transparent;
      box-shadow: none; }
    .button.is-dark.is-inverted {
      background-color: whitesmoke;
      color: #363636; }
      .button.is-dark.is-inverted:hover {
        background-color: #e8e8e8; }
      .button.is-dark.is-inverted[disabled],
      fieldset[disabled] .button.is-dark.is-inverted {
        background-color: whitesmoke;
        border-color: transparent;
        box-shadow: none;
        color: #363636; }
    .button.is-dark.is-loading::after {
      border-color: transparent transparent whitesmoke whitesmoke !important; }
    .button.is-dark.is-outlined {
      background-color: transparent;
      border-color: #363636;
      color: #363636; }
      .button.is-dark.is-outlined:hover, .button.is-dark.is-outlined:focus {
        background-color: #363636;
        border-color: #363636;
        color: whitesmoke; }
      .button.is-dark.is-outlined.is-loading::after {
        border-color: transparent transparent #363636 #363636 !important; }
      .button.is-dark.is-outlined[disabled],
      fieldset[disabled] .button.is-dark.is-outlined {
        background-color: transparent;
        border-color: #363636;
        box-shadow: none;
        color: #363636; }
    .button.is-dark.is-inverted.is-outlined {
      background-color: transparent;
      border-color: whitesmoke;
      color: whitesmoke; }
      .button.is-dark.is-inverted.is-outlined:hover, .button.is-dark.is-inverted.is-outlined:focus {
        background-color: whitesmoke;
        color: #363636; }
      .button.is-dark.is-inverted.is-outlined[disabled],
      fieldset[disabled] .button.is-dark.is-inverted.is-outlined {
        background-color: transparent;
        border-color: whitesmoke;
        box-shadow: none;
        color: whitesmoke; }
  .button.is-primary {
    background-color: #00d1b2;
    border-color: transparent;
    color: #fff; }
    .button.is-primary:hover, .button.is-primary.is-hovered {
      background-color: #00c4a7;
      border-color: transparent;
      color: #fff; }
    .button.is-primary:focus, .button.is-primary.is-focused {
      border-color: transparent;
      color: #fff; }
      .button.is-primary:focus:not(:active), .button.is-primary.is-focused:not(:active) {
        box-shadow: 0 0 0 0.125em rgba(0, 209, 178, 0.25); }
    .button.is-primary:active, .button.is-primary.is-active {
      background-color: #00b89c;
      border-color: transparent;
      color: #fff; }
    .button.is-primary[disabled],
    fieldset[disabled] .button.is-primary {
      background-color: #00d1b2;
      border-color: transparent;
      box-shadow: none; }
    .button.is-primary.is-inverted {
      background-color: #fff;
      color: #00d1b2; }
      .button.is-primary.is-inverted:hover {
        background-color: #f2f2f2; }
      .button.is-primary.is-inverted[disabled],
      fieldset[disabled] .button.is-primary.is-inverted {
        background-color: #fff;
        border-color: transparent;
        box-shadow: none;
        color: #00d1b2; }
    .button.is-primary.is-loading::after {
      border-color: transparent transparent #fff #fff !important; }
    .button.is-primary.is-outlined {
      background-color: transparent;
      border-color: #00d1b2;
      color: #00d1b2; }
      .button.is-primary.is-outlined:hover, .button.is-primary.is-outlined:focus {
        background-color: #00d1b2;
        border-color: #00d1b2;
        color: #fff; }
      .button.is-primary.is-outlined.is-loading::after {
        border-color: transparent transparent #00d1b2 #00d1b2 !important; }
      .button.is-primary.is-outlined[disabled],
      fieldset[disabled] .button.is-primary.is-outlined {
        background-color: transparent;
        border-color: #00d1b2;
        box-shadow: none;
        color: #00d1b2; }
    .button.is-primary.is-inverted.is-outlined {
      background-color: transparent;
      border-color: #fff;
      color: #fff; }
      .button.is-primary.is-inverted.is-outlined:hover, .button.is-primary.is-inverted.is-outlined:focus {
        background-color: #fff;
        color: #00d1b2; }
      .button.is-primary.is-inverted.is-outlined[disabled],
      fieldset[disabled] .button.is-primary.is-inverted.is-outlined {
        background-color: transparent;
        border-color: #fff;
        box-shadow: none;
        color: #fff; }
  .button.is-link {
    background-color: #3273dc;
    border-color: transparent;
    color: #fff; }
    .button.is-link:hover, .button.is-link.is-hovered {
      background-color: #276cda;
      border-color: transparent;
      color: #fff; }
    .button.is-link:focus, .button.is-link.is-focused {
      border-color: transparent;
      color: #fff; }
      .button.is-link:focus:not(:active), .button.is-link.is-focused:not(:active) {
        box-shadow: 0 0 0 0.125em rgba(50, 115, 220, 0.25); }
    .button.is-link:active, .button.is-link.is-active {
      background-color: #2366d1;
      border-color: transparent;
      color: #fff; }
    .button.is-link[disabled],
    fieldset[disabled] .button.is-link {
      background-color: #3273dc;
      border-color: transparent;
      box-shadow: none; }
    .button.is-link.is-inverted {
      background-color: #fff;
      color: #3273dc; }
      .button.is-link.is-inverted:hover {
        background-color: #f2f2f2; }
      .button.is-link.is-inverted[disabled],
      fieldset[disabled] .button.is-link.is-inverted {
        background-color: #fff;
        border-color: transparent;
        box-shadow: none;
        color: #3273dc; }
    .button.is-link.is-loading::after {
      border-color: transparent transparent #fff #fff !important; }
    .button.is-link.is-outlined {
      background-color: transparent;
      border-color: #3273dc;
      color: #3273dc; }
      .button.is-link.is-outlined:hover, .button.is-link.is-outlined:focus {
        background-color: #3273dc;
        border-color: #3273dc;
        color: #fff; }
      .button.is-link.is-outlined.is-loading::after {
        border-color: transparent transparent #3273dc #3273dc !important; }
      .button.is-link.is-outlined[disabled],
      fieldset[disabled] .button.is-link.is-outlined {
        background-color: transparent;
        border-color: #3273dc;
        box-shadow: none;
        color: #3273dc; }
    .button.is-link.is-inverted.is-outlined {
      background-color: transparent;
      border-color: #fff;
      color: #fff; }
      .button.is-link.is-inverted.is-outlined:hover, .button.is-link.is-inverted.is-outlined:focus {
        background-color: #fff;
        color: #3273dc; }
      .button.is-link.is-inverted.is-outlined[disabled],
      fieldset[disabled] .button.is-link.is-inverted.is-outlined {
        background-color: transparent;
        border-color: #fff;
        box-shadow: none;
        color: #fff; }
  .button.is-info {
    background-color: #209cee;
    border-color: transparent;
    color: #fff; }
    .button.is-info:hover, .button.is-info.is-hovered {
      background-color: #1496ed;
      border-color: transparent;
      color: #fff; }
    .button.is-info:focus, .button.is-info.is-focused {
      border-color: transparent;
      color: #fff; }
      .button.is-info:focus:not(:active), .button.is-info.is-focused:not(:active) {
        box-shadow: 0 0 0 0.125em rgba(32, 156, 238, 0.25); }
    .button.is-info:active, .button.is-info.is-active {
      background-color: #118fe4;
      border-color: transparent;
      color: #fff; }
    .button.is-info[disabled],
    fieldset[disabled] .button.is-info {
      background-color: #209cee;
      border-color: transparent;
      box-shadow: none; }
    .button.is-info.is-inverted {
      background-color: #fff;
      color: #209cee; }
      .button.is-info.is-inverted:hover {
        background-color: #f2f2f2; }
      .button.is-info.is-inverted[disabled],
      fieldset[disabled] .button.is-info.is-inverted {
        background-color: #fff;
        border-color: transparent;
        box-shadow: none;
        color: #209cee; }
    .button.is-info.is-loading::after {
      border-color: transparent transparent #fff #fff !important; }
    .button.is-info.is-outlined {
      background-color: transparent;
      border-color: #209cee;
      color: #209cee; }
      .button.is-info.is-outlined:hover, .button.is-info.is-outlined:focus {
        background-color: #209cee;
        border-color: #209cee;
        color: #fff; }
      .button.is-info.is-outlined.is-loading::after {
        border-color: transparent transparent #209cee #209cee !important; }
      .button.is-info.is-outlined[disabled],
      fieldset[disabled] .button.is-info.is-outlined {
        background-color: transparent;
        border-color: #209cee;
        box-shadow: none;
        color: #209cee; }
    .button.is-info.is-inverted.is-outlined {
      background-color: transparent;
      border-color: #fff;
      color: #fff; }
      .button.is-info.is-inverted.is-outlined:hover, .button.is-info.is-inverted.is-outlined:focus {
        background-color: #fff;
        color: #209cee; }
      .button.is-info.is-inverted.is-outlined[disabled],
      fieldset[disabled] .button.is-info.is-inverted.is-outlined {
        background-color: transparent;
        border-color: #fff;
        box-shadow: none;
        color: #fff; }
  .button.is-success {
    background-color: #23d160;
    border-color: transparent;
    color: #fff; }
    .button.is-success:hover, .button.is-success.is-hovered {
      background-color: #22c65b;
      border-color: transparent;
      color: #fff; }
    .button.is-success:focus, .button.is-success.is-focused {
      border-color: transparent;
      color: #fff; }
      .button.is-success:focus:not(:active), .button.is-success.is-focused:not(:active) {
        box-shadow: 0 0 0 0.125em rgba(35, 209, 96, 0.25); }
    .button.is-success:active, .button.is-success.is-active {
      background-color: #20bc56;
      border-color: transparent;
      color: #fff; }
    .button.is-success[disabled],
    fieldset[disabled] .button.is-success {
      background-color: #23d160;
      border-color: transparent;
      box-shadow: none; }
    .button.is-success.is-inverted {
      background-color: #fff;
      color: #23d160; }
      .button.is-success.is-inverted:hover {
        background-color: #f2f2f2; }
      .button.is-success.is-inverted[disabled],
      fieldset[disabled] .button.is-success.is-inverted {
        background-color: #fff;
        border-color: transparent;
        box-shadow: none;
        color: #23d160; }
    .button.is-success.is-loading::after {
      border-color: transparent transparent #fff #fff !important; }
    .button.is-success.is-outlined {
      background-color: transparent;
      border-color: #23d160;
      color: #23d160; }
      .button.is-success.is-outlined:hover, .button.is-success.is-outlined:focus {
        background-color: #23d160;
        border-color: #23d160;
        color: #fff; }
      .button.is-success.is-outlined.is-loading::after {
        border-color: transparent transparent #23d160 #23d160 !important; }
      .button.is-success.is-outlined[disabled],
      fieldset[disabled] .button.is-success.is-outlined {
        background-color: transparent;
        border-color: #23d160;
        box-shadow: none;
        color: #23d160; }
    .button.is-success.is-inverted.is-outlined {
      background-color: transparent;
      border-color: #fff;
      color: #fff; }
      .button.is-success.is-inverted.is-outlined:hover, .button.is-success.is-inverted.is-outlined:focus {
        background-color: #fff;
        color: #23d160; }
      .button.is-success.is-inverted.is-outlined[disabled],
      fieldset[disabled] .button.is-success.is-inverted.is-outlined {
        background-color: transparent;
        border-color: #fff;
        box-shadow: none;
        color: #fff; }
  .button.is-warning {
    background-color: #ffdd57;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
    .button.is-warning:hover, .button.is-warning.is-hovered {
      background-color: #ffdb4a;
      border-color: transparent;
      color: rgba(0, 0, 0, 0.7); }
    .button.is-warning:focus, .button.is-warning.is-focused {
      border-color: transparent;
      color: rgba(0, 0, 0, 0.7); }
      .button.is-warning:focus:not(:active), .button.is-warning.is-focused:not(:active) {
        box-shadow: 0 0 0 0.125em rgba(255, 221, 87, 0.25); }
    .button.is-warning:active, .button.is-warning.is-active {
      background-color: #ffd83d;
      border-color: transparent;
      color: rgba(0, 0, 0, 0.7); }
    .button.is-warning[disabled],
    fieldset[disabled] .button.is-warning {
      background-color: #ffdd57;
      border-color: transparent;
      box-shadow: none; }
    .button.is-warning.is-inverted {
      background-color: rgba(0, 0, 0, 0.7);
      color: #ffdd57; }
      .button.is-warning.is-inverted:hover {
        background-color: rgba(0, 0, 0, 0.7); }
      .button.is-warning.is-inverted[disabled],
      fieldset[disabled] .button.is-warning.is-inverted {
        background-color: rgba(0, 0, 0, 0.7);
        border-color: transparent;
        box-shadow: none;
        color: #ffdd57; }
    .button.is-warning.is-loading::after {
      border-color: transparent transparent rgba(0, 0, 0, 0.7) rgba(0, 0, 0, 0.7) !important; }
    .button.is-warning.is-outlined {
      background-color: transparent;
      border-color: #ffdd57;
      color: #ffdd57; }
      .button.is-warning.is-outlined:hover, .button.is-warning.is-outlined:focus {
        background-color: #ffdd57;
        border-color: #ffdd57;
        color: rgba(0, 0, 0, 0.7); }
      .button.is-warning.is-outlined.is-loading::after {
        border-color: transparent transparent #ffdd57 #ffdd57 !important; }
      .button.is-warning.is-outlined[disabled],
      fieldset[disabled] .button.is-warning.is-outlined {
        background-color: transparent;
        border-color: #ffdd57;
        box-shadow: none;
        color: #ffdd57; }
    .button.is-warning.is-inverted.is-outlined {
      background-color: transparent;
      border-color: rgba(0, 0, 0, 0.7);
      color: rgba(0, 0, 0, 0.7); }
      .button.is-warning.is-inverted.is-outlined:hover, .button.is-warning.is-inverted.is-outlined:focus {
        background-color: rgba(0, 0, 0, 0.7);
        color: #ffdd57; }
      .button.is-warning.is-inverted.is-outlined[disabled],
      fieldset[disabled] .button.is-warning.is-inverted.is-outlined {
        background-color: transparent;
        border-color: rgba(0, 0, 0, 0.7);
        box-shadow: none;
        color: rgba(0, 0, 0, 0.7); }
  .button.is-danger {
    background-color: #ff3860;
    border-color: transparent;
    color: #fff; }
    .button.is-danger:hover, .button.is-danger.is-hovered {
      background-color: #ff2b56;
      border-color: transparent;
      color: #fff; }
    .button.is-danger:focus, .button.is-danger.is-focused {
      border-color: transparent;
      color: #fff; }
      .button.is-danger:focus:not(:active), .button.is-danger.is-focused:not(:active) {
        box-shadow: 0 0 0 0.125em rgba(255, 56, 96, 0.25); }
    .button.is-danger:active, .button.is-danger.is-active {
      background-color: #ff1f4b;
      border-color: transparent;
      color: #fff; }
    .button.is-danger[disabled],
    fieldset[disabled] .button.is-danger {
      background-color: #ff3860;
      border-color: transparent;
      box-shadow: none; }
    .button.is-danger.is-inverted {
      background-color: #fff;
      color: #ff3860; }
      .button.is-danger.is-inverted:hover {
        background-color: #f2f2f2; }
      .button.is-danger.is-inverted[disabled],
      fieldset[disabled] .button.is-danger.is-inverted {
        background-color: #fff;
        border-color: transparent;
        box-shadow: none;
        color: #ff3860; }
    .button.is-danger.is-loading::after {
      border-color: transparent transparent #fff #fff !important; }
    .button.is-danger.is-outlined {
      background-color: transparent;
      border-color: #ff3860;
      color: #ff3860; }
      .button.is-danger.is-outlined:hover, .button.is-danger.is-outlined:focus {
        background-color: #ff3860;
        border-color: #ff3860;
        color: #fff; }
      .button.is-danger.is-outlined.is-loading::after {
        border-color: transparent transparent #ff3860 #ff3860 !important; }
      .button.is-danger.is-outlined[disabled],
      fieldset[disabled] .button.is-danger.is-outlined {
        background-color: transparent;
        border-color: #ff3860;
        box-shadow: none;
        color: #ff3860; }
    .button.is-danger.is-inverted.is-outlined {
      background-color: transparent;
      border-color: #fff;
      color: #fff; }
      .button.is-danger.is-inverted.is-outlined:hover, .button.is-danger.is-inverted.is-outlined:focus {
        background-color: #fff;
        color: #ff3860; }
      .button.is-danger.is-inverted.is-outlined[disabled],
      fieldset[disabled] .button.is-danger.is-inverted.is-outlined {
        background-color: transparent;
        border-color: #fff;
        box-shadow: none;
        color: #fff; }
  .button.is-small {
    border-radius: 2px;
    font-size: 0.75rem; }
  .button.is-normal {
    font-size: 1rem; }
  .button.is-medium {
    font-size: 1.25rem; }
  .button.is-large {
    font-size: 1.5rem; }
  .button[disabled],
  fieldset[disabled] .button {
    background-color: white;
    border-color: #dbdbdb;
    box-shadow: none;
    opacity: 0.5; }
  .button.is-fullwidth {
    display: flex;
    width: 100%; }
  .button.is-loading {
    color: transparent !important;
    pointer-events: none; }
    .button.is-loading::after {
      position: absolute;
      left: calc(50% - (1em / 2));
      top: calc(50% - (1em / 2));
      position: absolute !important; }
  .button.is-static {
    background-color: whitesmoke;
    border-color: #dbdbdb;
    color: #7a7a7a;
    box-shadow: none;
    pointer-events: none; }
  .button.is-rounded {
    border-radius: 290486px;
    padding-left: 1em;
    padding-right: 1em; }

.buttons {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .buttons .button {
    margin-bottom: 0.5rem; }
    .buttons .button:not(:last-child):not(.is-fullwidth) {
      margin-right: 0.5rem; }
  .buttons:last-child {
    margin-bottom: -0.5rem; }
  .buttons:not(:last-child) {
    margin-bottom: 1rem; }
  .buttons.are-small .button:not(.is-normal):not(.is-medium):not(.is-large) {
    border-radius: 2px;
    font-size: 0.75rem; }
  .buttons.are-medium .button:not(.is-small):not(.is-normal):not(.is-large) {
    font-size: 1.25rem; }
  .buttons.are-large .button:not(.is-small):not(.is-normal):not(.is-medium) {
    font-size: 1.5rem; }
  .buttons.has-addons .button:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; }
  .buttons.has-addons .button:not(:last-child) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    margin-right: -1px; }
  .buttons.has-addons .button:last-child {
    margin-right: 0; }
  .buttons.has-addons .button:hover, .buttons.has-addons .button.is-hovered {
    z-index: 2; }
  .buttons.has-addons .button:focus, .buttons.has-addons .button.is-focused, .buttons.has-addons .button:active, .buttons.has-addons .button.is-active, .buttons.has-addons .button.is-selected {
    z-index: 3; }
    .buttons.has-addons .button:focus:hover, .buttons.has-addons .button.is-focused:hover, .buttons.has-addons .button:active:hover, .buttons.has-addons .button.is-active:hover, .buttons.has-addons .button.is-selected:hover {
      z-index: 4; }
  .buttons.has-addons .button.is-expanded {
    flex-grow: 1; }
  .buttons.is-centered {
    justify-content: center; }
  .buttons.is-right {
    justify-content: flex-end; }

.input,
.textarea {
  background-color: white;
  border-color: #dbdbdb;
  color: #363636;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  max-width: 100%;
  width: 100%; }
  .input::-moz-placeholder,
  .textarea::-moz-placeholder {
    color: rgba(54, 54, 54, 0.3); }
  .input::-webkit-input-placeholder,
  .textarea::-webkit-input-placeholder {
    color: rgba(54, 54, 54, 0.3); }
  .input:-moz-placeholder,
  .textarea:-moz-placeholder {
    color: rgba(54, 54, 54, 0.3); }
  .input:-ms-input-placeholder,
  .textarea:-ms-input-placeholder {
    color: rgba(54, 54, 54, 0.3); }
  .input:hover, .input.is-hovered,
  .textarea:hover,
  .textarea.is-hovered {
    border-color: #b5b5b5; }
  .input:focus, .input.is-focused, .input:active, .input.is-active,
  .textarea:focus,
  .textarea.is-focused,
  .textarea:active,
  .textarea.is-active {
    border-color: #3273dc;
    box-shadow: 0 0 0 0.125em rgba(50, 115, 220, 0.25); }
  .input[disabled],
  fieldset[disabled] .input,
  .textarea[disabled],
  fieldset[disabled]
  .textarea {
    background-color: whitesmoke;
    border-color: whitesmoke;
    box-shadow: none;
    color: #7a7a7a; }
    .input[disabled]::-moz-placeholder,
    fieldset[disabled] .input::-moz-placeholder,
    .textarea[disabled]::-moz-placeholder,
    fieldset[disabled]
    .textarea::-moz-placeholder {
      color: rgba(122, 122, 122, 0.3); }
    .input[disabled]::-webkit-input-placeholder,
    fieldset[disabled] .input::-webkit-input-placeholder,
    .textarea[disabled]::-webkit-input-placeholder,
    fieldset[disabled]
    .textarea::-webkit-input-placeholder {
      color: rgba(122, 122, 122, 0.3); }
    .input[disabled]:-moz-placeholder,
    fieldset[disabled] .input:-moz-placeholder,
    .textarea[disabled]:-moz-placeholder,
    fieldset[disabled]
    .textarea:-moz-placeholder {
      color: rgba(122, 122, 122, 0.3); }
    .input[disabled]:-ms-input-placeholder,
    fieldset[disabled] .input:-ms-input-placeholder,
    .textarea[disabled]:-ms-input-placeholder,
    fieldset[disabled]
    .textarea:-ms-input-placeholder {
      color: rgba(122, 122, 122, 0.3); }
  .input[readonly],
  .textarea[readonly] {
    box-shadow: none; }
  .input.is-white,
  .textarea.is-white {
    border-color: white; }
    .input.is-white:focus, .input.is-white.is-focused, .input.is-white:active, .input.is-white.is-active,
    .textarea.is-white:focus,
    .textarea.is-white.is-focused,
    .textarea.is-white:active,
    .textarea.is-white.is-active {
      box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 0.25); }
  .input.is-black,
  .textarea.is-black {
    border-color: #0a0a0a; }
    .input.is-black:focus, .input.is-black.is-focused, .input.is-black:active, .input.is-black.is-active,
    .textarea.is-black:focus,
    .textarea.is-black.is-focused,
    .textarea.is-black:active,
    .textarea.is-black.is-active {
      box-shadow: 0 0 0 0.125em rgba(10, 10, 10, 0.25); }
  .input.is-light,
  .textarea.is-light {
    border-color: whitesmoke; }
    .input.is-light:focus, .input.is-light.is-focused, .input.is-light:active, .input.is-light.is-active,
    .textarea.is-light:focus,
    .textarea.is-light.is-focused,
    .textarea.is-light:active,
    .textarea.is-light.is-active {
      box-shadow: 0 0 0 0.125em rgba(245, 245, 245, 0.25); }
  .input.is-dark,
  .textarea.is-dark {
    border-color: #363636; }
    .input.is-dark:focus, .input.is-dark.is-focused, .input.is-dark:active, .input.is-dark.is-active,
    .textarea.is-dark:focus,
    .textarea.is-dark.is-focused,
    .textarea.is-dark:active,
    .textarea.is-dark.is-active {
      box-shadow: 0 0 0 0.125em rgba(54, 54, 54, 0.25); }
  .input.is-primary,
  .textarea.is-primary {
    border-color: #00d1b2; }
    .input.is-primary:focus, .input.is-primary.is-focused, .input.is-primary:active, .input.is-primary.is-active,
    .textarea.is-primary:focus,
    .textarea.is-primary.is-focused,
    .textarea.is-primary:active,
    .textarea.is-primary.is-active {
      box-shadow: 0 0 0 0.125em rgba(0, 209, 178, 0.25); }
  .input.is-link,
  .textarea.is-link {
    border-color: #3273dc; }
    .input.is-link:focus, .input.is-link.is-focused, .input.is-link:active, .input.is-link.is-active,
    .textarea.is-link:focus,
    .textarea.is-link.is-focused,
    .textarea.is-link:active,
    .textarea.is-link.is-active {
      box-shadow: 0 0 0 0.125em rgba(50, 115, 220, 0.25); }
  .input.is-info,
  .textarea.is-info {
    border-color: #209cee; }
    .input.is-info:focus, .input.is-info.is-focused, .input.is-info:active, .input.is-info.is-active,
    .textarea.is-info:focus,
    .textarea.is-info.is-focused,
    .textarea.is-info:active,
    .textarea.is-info.is-active {
      box-shadow: 0 0 0 0.125em rgba(32, 156, 238, 0.25); }
  .input.is-success,
  .textarea.is-success {
    border-color: #23d160; }
    .input.is-success:focus, .input.is-success.is-focused, .input.is-success:active, .input.is-success.is-active,
    .textarea.is-success:focus,
    .textarea.is-success.is-focused,
    .textarea.is-success:active,
    .textarea.is-success.is-active {
      box-shadow: 0 0 0 0.125em rgba(35, 209, 96, 0.25); }
  .input.is-warning,
  .textarea.is-warning {
    border-color: #ffdd57; }
    .input.is-warning:focus, .input.is-warning.is-focused, .input.is-warning:active, .input.is-warning.is-active,
    .textarea.is-warning:focus,
    .textarea.is-warning.is-focused,
    .textarea.is-warning:active,
    .textarea.is-warning.is-active {
      box-shadow: 0 0 0 0.125em rgba(255, 221, 87, 0.25); }
  .input.is-danger,
  .textarea.is-danger {
    border-color: #ff3860; }
    .input.is-danger:focus, .input.is-danger.is-focused, .input.is-danger:active, .input.is-danger.is-active,
    .textarea.is-danger:focus,
    .textarea.is-danger.is-focused,
    .textarea.is-danger:active,
    .textarea.is-danger.is-active {
      box-shadow: 0 0 0 0.125em rgba(255, 56, 96, 0.25); }
  .input.is-small,
  .textarea.is-small {
    border-radius: 2px;
    font-size: 0.75rem; }
  .input.is-medium,
  .textarea.is-medium {
    font-size: 1.25rem; }
  .input.is-large,
  .textarea.is-large {
    font-size: 1.5rem; }
  .input.is-fullwidth,
  .textarea.is-fullwidth {
    display: block;
    width: 100%; }
  .input.is-inline,
  .textarea.is-inline {
    display: inline;
    width: auto; }

.input.is-rounded {
  border-radius: 290486px;
  padding-left: 1em;
  padding-right: 1em; }

.input.is-static {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  padding-left: 0;
  padding-right: 0; }

.textarea {
  display: block;
  max-width: 100%;
  min-width: 100%;
  padding: 0.625em;
  resize: vertical; }
  .textarea:not([rows]) {
    max-height: 600px;
    min-height: 120px; }
  .textarea[rows] {
    height: auto;
    height: initial; }
  .textarea.has-fixed-size {
    resize: none; }

.checkbox,
.radio {
  cursor: pointer;
  display: inline-block;
  line-height: 1.25;
  position: relative; }
  .checkbox input,
  .radio input {
    cursor: pointer; }
  .checkbox:hover,
  .radio:hover {
    color: #363636; }
  .checkbox[disabled],
  fieldset[disabled] .checkbox,
  .radio[disabled],
  fieldset[disabled]
  .radio {
    color: #7a7a7a;
    cursor: not-allowed; }

.radio + .radio {
  margin-left: 0.5em; }

.select {
  display: inline-block;
  max-width: 100%;
  position: relative;
  vertical-align: top; }
  .select:not(.is-multiple) {
    height: 2.25em; }
  .select:not(.is-multiple):not(.is-loading)::after {
    border-color: #3273dc;
    right: 1.125em;
    z-index: 4; }
  .select.is-rounded select {
    border-radius: 290486px;
    padding-left: 1em; }
  .select select {
    background-color: white;
    border-color: #dbdbdb;
    color: #363636;
    cursor: pointer;
    display: block;
    font-size: 1em;
    max-width: 100%;
    outline: none; }
    .select select::-moz-placeholder {
      color: rgba(54, 54, 54, 0.3); }
    .select select::-webkit-input-placeholder {
      color: rgba(54, 54, 54, 0.3); }
    .select select:-moz-placeholder {
      color: rgba(54, 54, 54, 0.3); }
    .select select:-ms-input-placeholder {
      color: rgba(54, 54, 54, 0.3); }
    .select select:hover, .select select.is-hovered {
      border-color: #b5b5b5; }
    .select select:focus, .select select.is-focused, .select select:active, .select select.is-active {
      border-color: #3273dc;
      box-shadow: 0 0 0 0.125em rgba(50, 115, 220, 0.25); }
    .select select[disabled],
    fieldset[disabled] .select select {
      background-color: whitesmoke;
      border-color: whitesmoke;
      box-shadow: none;
      color: #7a7a7a; }
      .select select[disabled]::-moz-placeholder,
      fieldset[disabled] .select select::-moz-placeholder {
        color: rgba(122, 122, 122, 0.3); }
      .select select[disabled]::-webkit-input-placeholder,
      fieldset[disabled] .select select::-webkit-input-placeholder {
        color: rgba(122, 122, 122, 0.3); }
      .select select[disabled]:-moz-placeholder,
      fieldset[disabled] .select select:-moz-placeholder {
        color: rgba(122, 122, 122, 0.3); }
      .select select[disabled]:-ms-input-placeholder,
      fieldset[disabled] .select select:-ms-input-placeholder {
        color: rgba(122, 122, 122, 0.3); }
    .select select::-ms-expand {
      display: none; }
    .select select[disabled]:hover,
    fieldset[disabled] .select select:hover {
      border-color: whitesmoke; }
    .select select:not([multiple]) {
      padding-right: 2.5em; }
    .select select[multiple] {
      height: auto;
      padding: 0; }
      .select select[multiple] option {
        padding: 0.5em 1em; }
  .select:not(.is-multiple):not(.is-loading):hover::after {
    border-color: #363636; }
  .select.is-white:not(:hover)::after {
    border-color: white; }
  .select.is-white select {
    border-color: white; }
    .select.is-white select:hover, .select.is-white select.is-hovered {
      border-color: #f2f2f2; }
    .select.is-white select:focus, .select.is-white select.is-focused, .select.is-white select:active, .select.is-white select.is-active {
      box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 0.25); }
  .select.is-black:not(:hover)::after {
    border-color: #0a0a0a; }
  .select.is-black select {
    border-color: #0a0a0a; }
    .select.is-black select:hover, .select.is-black select.is-hovered {
      border-color: black; }
    .select.is-black select:focus, .select.is-black select.is-focused, .select.is-black select:active, .select.is-black select.is-active {
      box-shadow: 0 0 0 0.125em rgba(10, 10, 10, 0.25); }
  .select.is-light:not(:hover)::after {
    border-color: whitesmoke; }
  .select.is-light select {
    border-color: whitesmoke; }
    .select.is-light select:hover, .select.is-light select.is-hovered {
      border-color: #e8e8e8; }
    .select.is-light select:focus, .select.is-light select.is-focused, .select.is-light select:active, .select.is-light select.is-active {
      box-shadow: 0 0 0 0.125em rgba(245, 245, 245, 0.25); }
  .select.is-dark:not(:hover)::after {
    border-color: #363636; }
  .select.is-dark select {
    border-color: #363636; }
    .select.is-dark select:hover, .select.is-dark select.is-hovered {
      border-color: #292929; }
    .select.is-dark select:focus, .select.is-dark select.is-focused, .select.is-dark select:active, .select.is-dark select.is-active {
      box-shadow: 0 0 0 0.125em rgba(54, 54, 54, 0.25); }
  .select.is-primary:not(:hover)::after {
    border-color: #00d1b2; }
  .select.is-primary select {
    border-color: #00d1b2; }
    .select.is-primary select:hover, .select.is-primary select.is-hovered {
      border-color: #00b89c; }
    .select.is-primary select:focus, .select.is-primary select.is-focused, .select.is-primary select:active, .select.is-primary select.is-active {
      box-shadow: 0 0 0 0.125em rgba(0, 209, 178, 0.25); }
  .select.is-link:not(:hover)::after {
    border-color: #3273dc; }
  .select.is-link select {
    border-color: #3273dc; }
    .select.is-link select:hover, .select.is-link select.is-hovered {
      border-color: #2366d1; }
    .select.is-link select:focus, .select.is-link select.is-focused, .select.is-link select:active, .select.is-link select.is-active {
      box-shadow: 0 0 0 0.125em rgba(50, 115, 220, 0.25); }
  .select.is-info:not(:hover)::after {
    border-color: #209cee; }
  .select.is-info select {
    border-color: #209cee; }
    .select.is-info select:hover, .select.is-info select.is-hovered {
      border-color: #118fe4; }
    .select.is-info select:focus, .select.is-info select.is-focused, .select.is-info select:active, .select.is-info select.is-active {
      box-shadow: 0 0 0 0.125em rgba(32, 156, 238, 0.25); }
  .select.is-success:not(:hover)::after {
    border-color: #23d160; }
  .select.is-success select {
    border-color: #23d160; }
    .select.is-success select:hover, .select.is-success select.is-hovered {
      border-color: #20bc56; }
    .select.is-success select:focus, .select.is-success select.is-focused, .select.is-success select:active, .select.is-success select.is-active {
      box-shadow: 0 0 0 0.125em rgba(35, 209, 96, 0.25); }
  .select.is-warning:not(:hover)::after {
    border-color: #ffdd57; }
  .select.is-warning select {
    border-color: #ffdd57; }
    .select.is-warning select:hover, .select.is-warning select.is-hovered {
      border-color: #ffd83d; }
    .select.is-warning select:focus, .select.is-warning select.is-focused, .select.is-warning select:active, .select.is-warning select.is-active {
      box-shadow: 0 0 0 0.125em rgba(255, 221, 87, 0.25); }
  .select.is-danger:not(:hover)::after {
    border-color: #ff3860; }
  .select.is-danger select {
    border-color: #ff3860; }
    .select.is-danger select:hover, .select.is-danger select.is-hovered {
      border-color: #ff1f4b; }
    .select.is-danger select:focus, .select.is-danger select.is-focused, .select.is-danger select:active, .select.is-danger select.is-active {
      box-shadow: 0 0 0 0.125em rgba(255, 56, 96, 0.25); }
  .select.is-small {
    border-radius: 2px;
    font-size: 0.75rem; }
  .select.is-medium {
    font-size: 1.25rem; }
  .select.is-large {
    font-size: 1.5rem; }
  .select.is-disabled::after {
    border-color: #7a7a7a; }
  .select.is-fullwidth {
    width: 100%; }
    .select.is-fullwidth select {
      width: 100%; }
  .select.is-loading::after {
    margin-top: 0;
    position: absolute;
    right: 0.625em;
    top: 0.625em;
    -webkit-transform: none;
            transform: none; }
  .select.is-loading.is-small:after {
    font-size: 0.75rem; }
  .select.is-loading.is-medium:after {
    font-size: 1.25rem; }
  .select.is-loading.is-large:after {
    font-size: 1.5rem; }

.file {
  align-items: stretch;
  display: flex;
  justify-content: flex-start;
  position: relative; }
  .file.is-white .file-cta {
    background-color: white;
    border-color: transparent;
    color: #0a0a0a; }
  .file.is-white:hover .file-cta, .file.is-white.is-hovered .file-cta {
    background-color: #f9f9f9;
    border-color: transparent;
    color: #0a0a0a; }
  .file.is-white:focus .file-cta, .file.is-white.is-focused .file-cta {
    border-color: transparent;
    box-shadow: 0 0 0.5em rgba(255, 255, 255, 0.25);
    color: #0a0a0a; }
  .file.is-white:active .file-cta, .file.is-white.is-active .file-cta {
    background-color: #f2f2f2;
    border-color: transparent;
    color: #0a0a0a; }
  .file.is-black .file-cta {
    background-color: #0a0a0a;
    border-color: transparent;
    color: white; }
  .file.is-black:hover .file-cta, .file.is-black.is-hovered .file-cta {
    background-color: #040404;
    border-color: transparent;
    color: white; }
  .file.is-black:focus .file-cta, .file.is-black.is-focused .file-cta {
    border-color: transparent;
    box-shadow: 0 0 0.5em rgba(10, 10, 10, 0.25);
    color: white; }
  .file.is-black:active .file-cta, .file.is-black.is-active .file-cta {
    background-color: black;
    border-color: transparent;
    color: white; }
  .file.is-light .file-cta {
    background-color: whitesmoke;
    border-color: transparent;
    color: #363636; }
  .file.is-light:hover .file-cta, .file.is-light.is-hovered .file-cta {
    background-color: #eeeeee;
    border-color: transparent;
    color: #363636; }
  .file.is-light:focus .file-cta, .file.is-light.is-focused .file-cta {
    border-color: transparent;
    box-shadow: 0 0 0.5em rgba(245, 245, 245, 0.25);
    color: #363636; }
  .file.is-light:active .file-cta, .file.is-light.is-active .file-cta {
    background-color: #e8e8e8;
    border-color: transparent;
    color: #363636; }
  .file.is-dark .file-cta {
    background-color: #363636;
    border-color: transparent;
    color: whitesmoke; }
  .file.is-dark:hover .file-cta, .file.is-dark.is-hovered .file-cta {
    background-color: #2f2f2f;
    border-color: transparent;
    color: whitesmoke; }
  .file.is-dark:focus .file-cta, .file.is-dark.is-focused .file-cta {
    border-color: transparent;
    box-shadow: 0 0 0.5em rgba(54, 54, 54, 0.25);
    color: whitesmoke; }
  .file.is-dark:active .file-cta, .file.is-dark.is-active .file-cta {
    background-color: #292929;
    border-color: transparent;
    color: whitesmoke; }
  .file.is-primary .file-cta {
    background-color: #00d1b2;
    border-color: transparent;
    color: #fff; }
  .file.is-primary:hover .file-cta, .file.is-primary.is-hovered .file-cta {
    background-color: #00c4a7;
    border-color: transparent;
    color: #fff; }
  .file.is-primary:focus .file-cta, .file.is-primary.is-focused .file-cta {
    border-color: transparent;
    box-shadow: 0 0 0.5em rgba(0, 209, 178, 0.25);
    color: #fff; }
  .file.is-primary:active .file-cta, .file.is-primary.is-active .file-cta {
    background-color: #00b89c;
    border-color: transparent;
    color: #fff; }
  .file.is-link .file-cta {
    background-color: #3273dc;
    border-color: transparent;
    color: #fff; }
  .file.is-link:hover .file-cta, .file.is-link.is-hovered .file-cta {
    background-color: #276cda;
    border-color: transparent;
    color: #fff; }
  .file.is-link:focus .file-cta, .file.is-link.is-focused .file-cta {
    border-color: transparent;
    box-shadow: 0 0 0.5em rgba(50, 115, 220, 0.25);
    color: #fff; }
  .file.is-link:active .file-cta, .file.is-link.is-active .file-cta {
    background-color: #2366d1;
    border-color: transparent;
    color: #fff; }
  .file.is-info .file-cta {
    background-color: #209cee;
    border-color: transparent;
    color: #fff; }
  .file.is-info:hover .file-cta, .file.is-info.is-hovered .file-cta {
    background-color: #1496ed;
    border-color: transparent;
    color: #fff; }
  .file.is-info:focus .file-cta, .file.is-info.is-focused .file-cta {
    border-color: transparent;
    box-shadow: 0 0 0.5em rgba(32, 156, 238, 0.25);
    color: #fff; }
  .file.is-info:active .file-cta, .file.is-info.is-active .file-cta {
    background-color: #118fe4;
    border-color: transparent;
    color: #fff; }
  .file.is-success .file-cta {
    background-color: #23d160;
    border-color: transparent;
    color: #fff; }
  .file.is-success:hover .file-cta, .file.is-success.is-hovered .file-cta {
    background-color: #22c65b;
    border-color: transparent;
    color: #fff; }
  .file.is-success:focus .file-cta, .file.is-success.is-focused .file-cta {
    border-color: transparent;
    box-shadow: 0 0 0.5em rgba(35, 209, 96, 0.25);
    color: #fff; }
  .file.is-success:active .file-cta, .file.is-success.is-active .file-cta {
    background-color: #20bc56;
    border-color: transparent;
    color: #fff; }
  .file.is-warning .file-cta {
    background-color: #ffdd57;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
  .file.is-warning:hover .file-cta, .file.is-warning.is-hovered .file-cta {
    background-color: #ffdb4a;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
  .file.is-warning:focus .file-cta, .file.is-warning.is-focused .file-cta {
    border-color: transparent;
    box-shadow: 0 0 0.5em rgba(255, 221, 87, 0.25);
    color: rgba(0, 0, 0, 0.7); }
  .file.is-warning:active .file-cta, .file.is-warning.is-active .file-cta {
    background-color: #ffd83d;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
  .file.is-danger .file-cta {
    background-color: #ff3860;
    border-color: transparent;
    color: #fff; }
  .file.is-danger:hover .file-cta, .file.is-danger.is-hovered .file-cta {
    background-color: #ff2b56;
    border-color: transparent;
    color: #fff; }
  .file.is-danger:focus .file-cta, .file.is-danger.is-focused .file-cta {
    border-color: transparent;
    box-shadow: 0 0 0.5em rgba(255, 56, 96, 0.25);
    color: #fff; }
  .file.is-danger:active .file-cta, .file.is-danger.is-active .file-cta {
    background-color: #ff1f4b;
    border-color: transparent;
    color: #fff; }
  .file.is-small {
    font-size: 0.75rem; }
  .file.is-medium {
    font-size: 1.25rem; }
    .file.is-medium .file-icon .fa {
      font-size: 21px; }
  .file.is-large {
    font-size: 1.5rem; }
    .file.is-large .file-icon .fa {
      font-size: 28px; }
  .file.has-name .file-cta {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
  .file.has-name .file-name {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; }
  .file.has-name.is-empty .file-cta {
    border-radius: 4px; }
  .file.has-name.is-empty .file-name {
    display: none; }
  .file.is-boxed .file-label {
    flex-direction: column; }
  .file.is-boxed .file-cta {
    flex-direction: column;
    height: auto;
    padding: 1em 3em; }
  .file.is-boxed .file-name {
    border-width: 0 1px 1px; }
  .file.is-boxed .file-icon {
    height: 1.5em;
    width: 1.5em; }
    .file.is-boxed .file-icon .fa {
      font-size: 21px; }
  .file.is-boxed.is-small .file-icon .fa {
    font-size: 14px; }
  .file.is-boxed.is-medium .file-icon .fa {
    font-size: 28px; }
  .file.is-boxed.is-large .file-icon .fa {
    font-size: 35px; }
  .file.is-boxed.has-name .file-cta {
    border-radius: 4px 4px 0 0; }
  .file.is-boxed.has-name .file-name {
    border-radius: 0 0 4px 4px;
    border-width: 0 1px 1px; }
  .file.is-centered {
    justify-content: center; }
  .file.is-fullwidth .file-label {
    width: 100%; }
  .file.is-fullwidth .file-name {
    flex-grow: 1;
    max-width: none; }
  .file.is-right {
    justify-content: flex-end; }
    .file.is-right .file-cta {
      border-radius: 0 4px 4px 0; }
    .file.is-right .file-name {
      border-radius: 4px 0 0 4px;
      border-width: 1px 0 1px 1px;
      order: -1; }

.file-label {
  align-items: stretch;
  display: flex;
  cursor: pointer;
  justify-content: flex-start;
  overflow: hidden;
  position: relative; }
  .file-label:hover .file-cta {
    background-color: #eeeeee;
    color: #363636; }
  .file-label:hover .file-name {
    border-color: #d5d5d5; }
  .file-label:active .file-cta {
    background-color: #e8e8e8;
    color: #363636; }
  .file-label:active .file-name {
    border-color: #cfcfcf; }

.file-input {
  height: 100%;
  left: 0;
  opacity: 0;
  outline: none;
  position: absolute;
  top: 0;
  width: 100%; }

.file-cta,
.file-name {
  border-color: #dbdbdb;
  border-radius: 4px;
  font-size: 1em;
  padding-left: 1em;
  padding-right: 1em;
  white-space: nowrap; }

.file-cta {
  background-color: whitesmoke;
  color: #4a4a4a; }

.file-name {
  border-color: #dbdbdb;
  border-style: solid;
  border-width: 1px 1px 1px 0;
  display: block;
  max-width: 16em;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis; }

.file-icon {
  align-items: center;
  display: flex;
  height: 1em;
  justify-content: center;
  margin-right: 0.5em;
  width: 1em; }
  .file-icon .fa {
    font-size: 14px; }

.label {
  color: #363636;
  display: block;
  font-size: 1rem;
  font-weight: 700; }
  .label:not(:last-child) {
    margin-bottom: 0.5em; }
  .label.is-small {
    font-size: 0.75rem; }
  .label.is-medium {
    font-size: 1.25rem; }
  .label.is-large {
    font-size: 1.5rem; }

.help {
  display: block;
  font-size: 0.75rem;
  margin-top: 0.25rem; }
  .help.is-white {
    color: white; }
  .help.is-black {
    color: #0a0a0a; }
  .help.is-light {
    color: whitesmoke; }
  .help.is-dark {
    color: #363636; }
  .help.is-primary {
    color: #00d1b2; }
  .help.is-link {
    color: #3273dc; }
  .help.is-info {
    color: #209cee; }
  .help.is-success {
    color: #23d160; }
  .help.is-warning {
    color: #ffdd57; }
  .help.is-danger {
    color: #ff3860; }

.field:not(:last-child) {
  margin-bottom: 0.75rem; }

.field.has-addons {
  display: flex;
  justify-content: flex-start; }
  .field.has-addons .control:not(:last-child) {
    margin-right: -1px; }
  .field.has-addons .control:not(:first-child):not(:last-child) .button,
  .field.has-addons .control:not(:first-child):not(:last-child) .input,
  .field.has-addons .control:not(:first-child):not(:last-child) .select select {
    border-radius: 0; }
  .field.has-addons .control:first-child:not(:only-child) .button,
  .field.has-addons .control:first-child:not(:only-child) .input,
  .field.has-addons .control:first-child:not(:only-child) .select select {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
  .field.has-addons .control:last-child:not(:only-child) .button,
  .field.has-addons .control:last-child:not(:only-child) .input,
  .field.has-addons .control:last-child:not(:only-child) .select select {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; }
  .field.has-addons .control .button:not([disabled]):hover, .field.has-addons .control .button:not([disabled]).is-hovered,
  .field.has-addons .control .input:not([disabled]):hover,
  .field.has-addons .control .input:not([disabled]).is-hovered,
  .field.has-addons .control .select select:not([disabled]):hover,
  .field.has-addons .control .select select:not([disabled]).is-hovered {
    z-index: 2; }
  .field.has-addons .control .button:not([disabled]):focus, .field.has-addons .control .button:not([disabled]).is-focused, .field.has-addons .control .button:not([disabled]):active, .field.has-addons .control .button:not([disabled]).is-active,
  .field.has-addons .control .input:not([disabled]):focus,
  .field.has-addons .control .input:not([disabled]).is-focused,
  .field.has-addons .control .input:not([disabled]):active,
  .field.has-addons .control .input:not([disabled]).is-active,
  .field.has-addons .control .select select:not([disabled]):focus,
  .field.has-addons .control .select select:not([disabled]).is-focused,
  .field.has-addons .control .select select:not([disabled]):active,
  .field.has-addons .control .select select:not([disabled]).is-active {
    z-index: 3; }
    .field.has-addons .control .button:not([disabled]):focus:hover, .field.has-addons .control .button:not([disabled]).is-focused:hover, .field.has-addons .control .button:not([disabled]):active:hover, .field.has-addons .control .button:not([disabled]).is-active:hover,
    .field.has-addons .control .input:not([disabled]):focus:hover,
    .field.has-addons .control .input:not([disabled]).is-focused:hover,
    .field.has-addons .control .input:not([disabled]):active:hover,
    .field.has-addons .control .input:not([disabled]).is-active:hover,
    .field.has-addons .control .select select:not([disabled]):focus:hover,
    .field.has-addons .control .select select:not([disabled]).is-focused:hover,
    .field.has-addons .control .select select:not([disabled]):active:hover,
    .field.has-addons .control .select select:not([disabled]).is-active:hover {
      z-index: 4; }
  .field.has-addons .control.is-expanded {
    flex-grow: 1; }
  .field.has-addons.has-addons-centered {
    justify-content: center; }
  .field.has-addons.has-addons-right {
    justify-content: flex-end; }
  .field.has-addons.has-addons-fullwidth .control {
    flex-grow: 1;
    flex-shrink: 0; }

.field.is-grouped {
  display: flex;
  justify-content: flex-start; }
  .field.is-grouped > .control {
    flex-shrink: 0; }
    .field.is-grouped > .control:not(:last-child) {
      margin-bottom: 0;
      margin-right: 0.75rem; }
    .field.is-grouped > .control.is-expanded {
      flex-grow: 1;
      flex-shrink: 1; }
  .field.is-grouped.is-grouped-centered {
    justify-content: center; }
  .field.is-grouped.is-grouped-right {
    justify-content: flex-end; }
  .field.is-grouped.is-grouped-multiline {
    flex-wrap: wrap; }
    .field.is-grouped.is-grouped-multiline > .control:last-child, .field.is-grouped.is-grouped-multiline > .control:not(:last-child) {
      margin-bottom: 0.75rem; }
    .field.is-grouped.is-grouped-multiline:last-child {
      margin-bottom: -0.75rem; }
    .field.is-grouped.is-grouped-multiline:not(:last-child) {
      margin-bottom: 0; }

@media screen and (min-width: 769px), print {
  .field.is-horizontal {
    display: flex; } }

.field-label .label {
  font-size: inherit; }

@media screen and (max-width: 768px) {
  .field-label {
    margin-bottom: 0.5rem; } }

@media screen and (min-width: 769px), print {
  .field-label {
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 0;
    margin-right: 1.5rem;
    text-align: right; }
    .field-label.is-small {
      font-size: 0.75rem;
      padding-top: 0.375em; }
    .field-label.is-normal {
      padding-top: 0.375em; }
    .field-label.is-medium {
      font-size: 1.25rem;
      padding-top: 0.375em; }
    .field-label.is-large {
      font-size: 1.5rem;
      padding-top: 0.375em; } }

.field-body .field .field {
  margin-bottom: 0; }

@media screen and (min-width: 769px), print {
  .field-body {
    display: flex;
    flex-basis: 0;
    flex-grow: 5;
    flex-shrink: 1; }
    .field-body .field {
      margin-bottom: 0; }
    .field-body > .field {
      flex-shrink: 1; }
      .field-body > .field:not(.is-narrow) {
        flex-grow: 1; }
      .field-body > .field:not(:last-child) {
        margin-right: 0.75rem; } }

.control {
  box-sizing: border-box;
  clear: both;
  font-size: 1rem;
  position: relative;
  text-align: left; }
  .control.has-icons-left .input:focus ~ .icon,
  .control.has-icons-left .select:focus ~ .icon, .control.has-icons-right .input:focus ~ .icon,
  .control.has-icons-right .select:focus ~ .icon {
    color: #7a7a7a; }
  .control.has-icons-left .input.is-small ~ .icon,
  .control.has-icons-left .select.is-small ~ .icon, .control.has-icons-right .input.is-small ~ .icon,
  .control.has-icons-right .select.is-small ~ .icon {
    font-size: 0.75rem; }
  .control.has-icons-left .input.is-medium ~ .icon,
  .control.has-icons-left .select.is-medium ~ .icon, .control.has-icons-right .input.is-medium ~ .icon,
  .control.has-icons-right .select.is-medium ~ .icon {
    font-size: 1.25rem; }
  .control.has-icons-left .input.is-large ~ .icon,
  .control.has-icons-left .select.is-large ~ .icon, .control.has-icons-right .input.is-large ~ .icon,
  .control.has-icons-right .select.is-large ~ .icon {
    font-size: 1.5rem; }
  .control.has-icons-left .icon, .control.has-icons-right .icon {
    color: #dbdbdb;
    height: 2.25em;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 2.25em;
    z-index: 4; }
  .control.has-icons-left .input,
  .control.has-icons-left .select select {
    padding-left: 2.25em; }
  .control.has-icons-left .icon.is-left {
    left: 0; }
  .control.has-icons-right .input,
  .control.has-icons-right .select select {
    padding-right: 2.25em; }
  .control.has-icons-right .icon.is-right {
    right: 0; }
  .control.is-loading::after {
    position: absolute !important;
    right: 0.625em;
    top: 0.625em;
    z-index: 4; }
  .control.is-loading.is-small:after {
    font-size: 0.75rem; }
  .control.is-loading.is-medium:after {
    font-size: 1.25rem; }
  .control.is-loading.is-large:after {
    font-size: 1.5rem; }

.heading {
  display: block;
  font-size: 11px;
  letter-spacing: 1px;
  margin-bottom: 5px;
  text-transform: uppercase; }

.highlight {
  font-weight: 400;
  max-width: 100%;
  overflow: hidden;
  padding: 0; }
  .highlight pre {
    overflow: auto;
    max-width: 100%; }

.number {
  align-items: center;
  background-color: whitesmoke;
  border-radius: 290486px;
  display: inline-flex;
  font-size: 1.25rem;
  height: 2em;
  justify-content: center;
  margin-right: 1.5rem;
  min-width: 2.5em;
  padding: 0.25rem 0.5rem;
  text-align: center;
  vertical-align: top; }

/* MAPBOX CSS ADJUSTMENTS */
.mapboxgl-ctrl-top-right {
  top: 80px; }

.mapboxgl-ctrl-top-right .mapboxgl-ctrl.mapboxgl-ctrl-group {
  margin: 0 12px 0 0; }

/* MOBILE */
@media (max-width: 640px) {
  /* MAPBOX CSS ADJUSTMENTS */
  .mapboxgl-ctrl-top-right .mapboxgl-ctrl.mapboxgl-ctrl-group {
    margin: 0 5px 0 0; }
  .mapboxgl-ctrl-bottom-left .mapboxgl-ctrl {
    margin: 0 0 3px 7px; }
  .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl.mapboxgl-ctrl-attrib.mapboxgl-compact {
    margin: 0 7px 3px 0; }
  .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-attrib.mapboxgl-compact::after {
    height: 20px;
    width: 20px; }
  .mapboxgl-ctrl-logo {
    width: 70px !important; } }

/* GLOBAL VARIABLES */
/*$color-grey-background: #e2e2e2;*/
/*$color-grey-background: #ececec;*/
.Navbar_navbar__10UeP {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  z-index: 20;
  min-height: auto;
  background-color: white;
  box-shadow: 0px 20px 15px rgba(25, 5, 5, 0.3); }

.Navbar_navbarBrand__32j1A {
  min-height: auto;
  height: 100%;
  margin-left: 25px; }

.Navbar_logoContainer__3anCr {
  padding: 0;
  margin: 0;
  height: 100%; }

img.Navbar_logo__46AHD {
  max-height: none;
  height: 80%; }

.Navbar_navbarBurgerContainer__B3wFX {
  margin-left: auto;
  padding-right: 0; }

.Navbar_navbarBurger__2oLzw, .Navbar_navbarBurger__2oLzw:focus, .Navbar_navbarBurger__2oLzw:active {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  box-shadow: 0px 0px 7px 0px #231818 !important;
  border: none;
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0); }

.Navbar_navbarBurger__2oLzw span {
  height: 2px;
  color: #0A64B8; }

.Navbar_navbarBurger__2oLzw span:nth-child(1) {
  top: 12.5px;
  left: 13.5px;
  width: 18px;
  -webkit-transform-origin: left top;
          transform-origin: left top;
  -webkit-transition: -webkit-transform .2s ease-out;
  transition: -webkit-transform .2s ease-out;
  transition: transform .2s ease-out;
  transition: transform .2s ease-out, -webkit-transform .2s ease-out; }

.Navbar_navbarBurger__2oLzw span:nth-child(2) {
  top: 21.5px;
  left: 10.5px;
  width: 24px;
  opacity: 1;
  -webkit-transition: opacity .2s ease-out;
  transition: opacity .2s ease-out; }

.Navbar_navbarBurger__2oLzw span:nth-child(3) {
  top: 30.5px;
  left: 13.5px;
  width: 18px;
  -webkit-transform-origin: left bottom;
          transform-origin: left bottom;
  -webkit-transition: -webkit-transform .2s ease-out;
  transition: -webkit-transform .2s ease-out;
  transition: transform .2s ease-out;
  transition: transform .2s ease-out, -webkit-transform .2s ease-out; }

.Navbar_navbarMenu__Xuyn9 {
  margin-right: 30px;
  padding: 0; }

.Navbar_navbarItem__378gu {
  margin-left: 20px;
  padding: 0; }

.Navbar_navbarItem__378gu:first-child {
  margin-left: 0; }

.Navbar_navbarButton__1DTX- {
  border: none;
  color: #002e5d;
  font-size: 1.05rem !important;
  font: inherit;
  text-decoration: none;
  padding-left: 0.75em;
  padding-right: 0.75em; }

.Navbar_navbarButton__1DTX-:focus {
  color: #002e5d;
  box-shadow: none !important; }

.Navbar_navbarButton__1DTX-:hover, .Navbar_navbarButton__1DTX-:active {
  color: #00aec7;
  box-shadow: none !important; }

button.Navbar_authButton__l5OKq {
  position: relative;
  height: 70%;
  width: 165px;
  border-radius: 7px;
  background-color: #002e5d;
  color: white;
  font-size: .9rem !important;
  padding: 0;
  margin-left: 10px; }

.Navbar_authButtonContent__PddmT {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border-radius: 7px; }

.Navbar_authButtonContent__PddmT:hover {
  background-color: #114982; }

button.Navbar_authButton__l5OKq:focus {
  background-color: #002e5d;
  color: white; }

button.Navbar_authButton__l5OKq:hover, button.Navbar_authButton__l5OKq:active {
  color: white; }

.Navbar_adminIcon__1G5oM {
  height: 23px;
  width: 23px;
  stroke: white;
  fill: #00aec7;
  stroke-width: 40;
  margin-right: 15px; }

.Navbar_authIcon__3o_Ei {
  height: 20px;
  width: 20px;
  stroke: white;
  stroke-width: 30;
  margin-right: 15px; }

.Navbar_authText__vLakB {
  margin-right: 10px; }

.Navbar_arrowDownIcon__2tvk4 {
  height: 30px;
  width: 30px;
  fill: white; }

.Navbar_dropdown__3XjFZ {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  top: calc(100% + 2px);
  right: 0;
  width: 100%;
  background-color: white;
  border: 1px solid #002e5d;
  border-top: none;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
  color: #002e5d;
  font-size: 1rem;
  padding: 5px 0;
  -webkit-transform-origin: top center;
          transform-origin: top center;
  -webkit-transform: scaleY(0.1);
          transform: scaleY(0.1);
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out; }

.Navbar_dropdown__3XjFZ.Navbar_show__1PlMz {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scaleY(1);
          transform: scaleY(1); }

.Navbar_dropdown__3XjFZ ul {
  list-style: none;
  margin: 0;
  padding: 0; }

.Navbar_dropdown__3XjFZ li {
  margin: 2px 0; }

.Navbar_dropdown__3XjFZ li:hover {
  color: #00aec7; }

@media (max-width: 1023px) {
  .Navbar_navbarBrand__32j1A {
    margin-right: 25px; }
  .Navbar_navbarBurger__2oLzw.Navbar_close__1LJnb span:nth-child(1) {
    -webkit-transform: rotate(45deg) scaleX(1.45);
            transform: rotate(45deg) scaleX(1.45); }
  .Navbar_navbarBurger__2oLzw.Navbar_close__1LJnb span:nth-child(2) {
    opacity: 0; }
  .Navbar_navbarBurger__2oLzw.Navbar_close__1LJnb span:nth-child(3) {
    -webkit-transform: rotate(-45deg) scaleX(1.45);
            transform: rotate(-45deg) scaleX(1.45); }
  .Navbar_navbarMenu__Xuyn9 {
    margin-right: 0;
    display: block;
    visibility: hidden;
    opacity: 0;
    -webkit-transform-origin: top center;
            transform-origin: top center;
    -webkit-transform: scaleY(0.1);
            transform: scaleY(0.1);
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out; }
  .Navbar_navbarMenu__Xuyn9.Navbar_shown__YCYBq {
    visibility: visible;
    opacity: 1;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    box-shadow: 0px 20px 10px rgba(25, 5, 5, 0.3); }
  .Navbar_navbarMenuItems__jiWMy {
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0; }
  .Navbar_navbarItem__378gu {
    margin-left: 0; }
  button.Navbar_authButton__l5OKq {
    height: 35px; } }

@media (max-width: 500px) {
  .Navbar_logoContainer__3anCr {
    height: 100%;
    width: 70%; }
  img.Navbar_logo__46AHD {
    height: auto;
    width: 100%; } }

/* GLOBAL VARIABLES */
/*$color-grey-background: #e2e2e2;*/
/*$color-grey-background: #ececec;*/
.Welcome_header__1yBUf {
  margin: 0 0 20px;
  text-align: center;
  font-size: 2rem; }

.Welcome_headerDark__31eSr {
  color: #002e5d; }

.Welcome_headerLight__3cD39 {
  color: #00aec7; }

.Welcome_paragraph1__3TBMs {
  text-align: justify; }

.Welcome_paragraph2__GN-fC {
  text-align: center; }

.Welcome_nameDark__3tW-7 {
  color: #002e5d;
  font-size: 1.1rem;
  font-weight: bold; }

.Welcome_nameLight__2nTah {
  color: #00aec7;
  font-size: 1.1rem;
  font-weight: bold; }

.Welcome_iframeContainer__1iZqB {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
  margin-top: 25px; }

.Welcome_iframe__eQbcO {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.Welcome_socialMediaHeader__c6csj {
  margin: 25px 0 10px;
  text-align: center;
  font-size: 1.1rem;
  font-weight: bold;
  color: #002e5d; }

.Welcome_socialMediaIcons__3mY9y {
  width: 40%;
  margin: 0 auto 20px;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.Welcome_socialMediaIcon__tRmtQ {
  fill: #00aec7;
  cursor: pointer; }

.Welcome_socialMediaIcon__tRmtQ:hover {
  fill: #002e5d; }

.Welcome_email__RPTaY {
  height: 22px;
  width: 22px; }

.Welcome_twitter__3G17F {
  height: 22px;
  width: 22px; }

.Welcome_facebook__iXY6d {
  height: 20px;
  width: 20px; }

.Welcome_instagram__CLo-y {
  height: 22px;
  width: 22px; }

.Welcome_logosRow__3qMBT {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto; }

.Welcome_logosRow__3qMBT.Welcome_second__1V0Es {
  width: 80%;
  margin-top: 15px;
  margin-bottom: 20px; }

.Welcome_logosRow__3qMBT.Welcome_third__2KSY2 {
  margin-bottom: 10px; }

.Welcome_logoVicerrectorado__ZuM7d {
  max-width: 40%; }

.Welcome_logoAndTech__1ZlfO {
  max-width: 25%; }

.Welcome_logoRedCatedras__2Kv_C {
  max-width: 25%; }

.Welcome_logoInteractividad__2iQ6g {
  max-width: 40%; }

.Welcome_logoGeotecnologia__3WtX8 {
  max-width: 40%; }

.Welcome_logoAyuntamiento__zq6KB {
  max-width: 25%; }

.Welcome_logoPromalaga__hEeNc {
  max-width: 25%; }

.Welcome_logoPolo__3VbKQ {
  max-width: 25%; }

.Welcome_logoCartometrics__3nNnT {
  max-width: 25%; }

/* GLOBAL VARIABLES */
/*$color-grey-background: #e2e2e2;*/
/*$color-grey-background: #ececec;*/
.NewInitiativeButton_container__3K5Sp {
  position: absolute;
  bottom: 35px;
  right: 12px; }

.NewInitiativeButton_newInitiativeButton__3lhAV {
  position: relative;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
  outline: none;
  border: 2px solid #00aec7; }

.NewInitiativeButton_newInitiativeButton__3lhAV:hover {
  box-shadow: 0px 0px 10px 0px #ffeab0; }

.NewInitiativeButton_newInitiativeButton__3lhAV.NewInitiativeButton_selected__1-yti {
  background-color: #00aec7;
  box-shadow: 0px 0px 20px 5px #ffeab0; }

.NewInitiativeButton_icon__3-oyi {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 80%;
  fill: #00aec7; }

.NewInitiativeButton_newInitiativeButton__3lhAV.NewInitiativeButton_selected__1-yti .NewInitiativeButton_icon__3-oyi {
  fill: white; }

/* MOBILE */
@media (max-width: 640px) {
  .NewInitiativeButton_container__3K5Sp {
    right: 5px;
    bottom: 30px; }
  .NewInitiativeButton_newInitiativeButton__3lhAV {
    height: 50px;
    width: 50px; } }

/* GLOBAL VARIABLES */
/*$color-grey-background: #e2e2e2;*/
/*$color-grey-background: #ececec;*/
.PageModal_modal__iHSO_ {
  position: absolute;
  width: 100%;
  top: 60px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  overflow-y: scroll;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out; }

.PageModal_modal__iHSO_.PageModal_show__33LdS {
  visibility: visible;
  opacity: 1; }

.PageModal_modalBackground__LZqHl {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.PageModal_modalDialog__3O_fk {
  position: relative;
  margin: 50px auto 5px;
  max-width: 95%;
  width: 500px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px #231818;
  background-color: white;
  padding: 15px 40px; }

.PageModal_closeButton__1FxQC {
  position: absolute;
  top: 6px;
  right: 6px;
  background-color: white;
  border: 1px solid #00aec7; }

.PageModal_closeButton__1FxQC:hover {
  background-color: rgba(0, 174, 199, 0.1); }

.PageModal_closeButton__1FxQC:focus {
  background-color: white; }

.PageModal_closeButton__1FxQC::before, .PageModal_closeButton__1FxQC::after {
  background-color: #00aec7; }

@media (max-width: 430px) {
  .PageModal_modal__iHSO_ {
    top: 50px; }
  .PageModal_modalDialog__3O_fk {
    padding: 10px 15px; } }

/* Style Scrollbar (Webkit only and only for desktop) */
@media (min-width: 1024px) {
  .PageModal_modal__iHSO_::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: transparent;
    border-radius: 10px; }
  .PageModal_modal__iHSO_::-webkit-scrollbar {
    width: 10px;
    border-radius: 10px;
    background-color: transparent; }
  .PageModal_modal__iHSO_::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 1px solid transparent;
    background-clip: padding-box;
    background-color: rgba(0, 46, 93, 0.8); } }

/* GLOBAL VARIABLES */
/*$color-grey-background: #e2e2e2;*/
/*$color-grey-background: #ececec;*/
.AuthForm_header__1UrAh {
  margin: 0;
  text-align: center;
  font-size: 1.5rem;
  color: #0A64B8; }

.AuthForm_authErrorMessage__ddyW4 {
  margin: 10px 20px 0;
  color: #ff3860;
  font-size: .95rem; }

.AuthForm_resetText__TYRrT {
  margin: 15px 20px 0;
  font-size: .95rem; }

.AuthForm_formFields__27uaj {
  margin-top: 15px;
  padding: 15px 20px;
  border-radius: 5px;
  background-color: #f1f1f1; }

.AuthForm_field__15lzI:not(:last-child) {
  margin-bottom: 7px; }

.AuthForm_input__1xLAC {
  font: inherit; }

.AuthForm_input__1xLAC:hover {
  border-color: rgba(0, 174, 199, 0.4); }

.AuthForm_input__1xLAC:focus {
  border-color: #00aec7;
  box-shadow: 0 0 0 0.125em rgba(0, 174, 199, 0.2); }

.AuthForm_input__1xLAC::-webkit-input-placeholder {
  color: rgba(0, 174, 199, 0.4); }

.AuthForm_input__1xLAC::-moz-placeholder {
  color: rgba(0, 174, 199, 0.4); }

.AuthForm_input__1xLAC::-ms-input-placeholder {
  color: rgba(0, 174, 199, 0.4); }

.AuthForm_input__1xLAC::placeholder {
  color: rgba(0, 174, 199, 0.4); }

.AuthForm_input__1xLAC.AuthForm_invalid__9H8FS {
  border-color: #ff3860; }

.AuthForm_inputIconContainer__2KC3M {
  padding-left: 5px; }

.AuthForm_inputIcon__1x6W5 {
  position: absolute;
  top: 21%;
  height: 50%;
  fill: #00aec7; }

.AuthForm_membersField__3gtSG {
  position: relative;
  margin-bottom: 25px !important; }

.AuthForm_membersField__3gtSG input {
  padding-right: 25px; }

.AuthForm_deleteIcon__3wJq2 {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 3px;
  height: 18px;
  width: 18px;
  fill: #00aec7;
  cursor: pointer; }

.AuthForm_addButton__3Idv3 {
  position: absolute;
  bottom: -19px;
  left: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  outline: none; }

.AuthForm_addIcon__1iAuy {
  height: 18px;
  width: 18px;
  fill: #00aec7; }

.AuthForm_addText__1eqHC {
  margin-left: 2px;
  font-size: .6rem;
  color: #00aec7; }

.AuthForm_addButton__3Idv3:focus .AuthForm_addText__1eqHC {
  text-decoration: underline; }

.AuthForm_colorInput__ZDkZn {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 9px;
  font-weight: normal;
  color: rgba(0, 174, 199, 0.4);
  background-color: white;
  border: 1px solid #dbdbdb;
  border-radius: 4px; }

.AuthForm_colorInputLabel__2F2kH {
  width: 45%;
  width: 130px;
  padding-bottom: 4px; }

.AuthForm_colors__Uqg_A {
  width: calc(100% - 130px);
  display: flex;
  justify-content: space-between;
  align-items: center; }

.AuthForm_colorBox__3dA72 {
  height: 15px;
  width: 25%;
  border-radius: 5px;
  border: 2px solid #f1f1f1;
  cursor: pointer;
  -webkit-transform-origin: center center;
          transform-origin: center center;
  outline: none; }

.AuthForm_colorBox__3dA72.AuthForm_selected__16hjV {
  height: 24px;
  box-shadow: 0px 0px 6px 0px #888b8d; }

.AuthForm_colorBox__3dA72:focus {
  box-shadow: 0px 0px 5px 0px #888b8d; }

.AuthForm_colorBox__3dA72.AuthForm_selected__16hjV:focus {
  box-shadow: 0px 0px 8px 0px #888b8d; }

@media (hover: hover) {
  .AuthForm_colorBox__3dA72:hover {
    box-shadow: 0px 0px 7px 0px #888b8d; } }

.AuthForm_conditions__3CJG1 {
  margin-top: 15px;
  padding: 0 20px;
  font-size: .95rem; }

.AuthForm_conditions__3CJG1 label {
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 1px 0; }

.AuthForm_conditions__3CJG1 label.AuthForm_invalid__9H8FS {
  border-color: #ff3860; }

.AuthForm_conditions__3CJG1 label a {
  color: #0A64B8;
  text-decoration: none; }

.AuthForm_conditions__3CJG1 label a:hover {
  text-decoration: underline; }

.AuthForm_inputErrorMessage__y8RoW {
  margin: 0 0 5px;
  padding-left: 10px;
  color: #ff3860;
  font-size: .85rem; }

.AuthForm_submitButton__1Rf5H {
  display: block;
  width: 50%;
  height: 40px;
  margin: 20px auto 0;
  background-color: #0A64B8;
  color: white;
  font-size: 1.1rem;
  border-radius: 7px; }

.AuthForm_button__3naTR.AuthForm_delete__1Y___ {
  background-color: #cc1338; }

.AuthForm_submitButton__1Rf5H:focus {
  color: white !important; }

.AuthForm_submitButton__1Rf5H:hover, .AuthForm_submitButton__1Rf5H:active {
  opacity: .9;
  color: white; }

.AuthForm_submitButton__1Rf5H:disabled {
  background-color: #0A64B8;
  opacity: .4; }

.AuthForm_link__2_zkV {
  margin: 15px 0 0;
  text-align: center;
  font-size: .95rem;
  color: #00aec7;
  opacity: .7;
  cursor: pointer;
  outline: none; }

.AuthForm_link__2_zkV:hover, .AuthForm_link__2_zkV:focus {
  text-decoration: underline;
  opacity: 1; }

.AuthForm_authFormBlank__278H_ {
  display: flex;
  justify-content: center;
  align-items: center; }

.AuthForm_loadingColorsError__1L_iH {
  color: #ff3860;
  font-size: .95rem; }

/* EDIT & DELETE */
.AuthForm_passwordText__32i6q {
  margin: 30px 0 5px 0; }

.AuthForm_deleteQuestion__1Y9ct {
  margin-top: 0;
  text-align: center;
  font-size: 1.1rem; }

.AuthForm_deleteNote__2qEZf {
  text-align: center;
  font-size: .8rem;
  color: #ff3860; }

.AuthForm_deleteNote__2qEZf p {
  margin: 0; }

.AuthForm_deleteButtonsContainer__3MVFg {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.AuthForm_deleteButton__3zmWb {
  display: block;
  width: 40%;
  height: 40px;
  margin: 15px auto 0;
  font-size: 1.1rem;
  border-radius: 7px; }

.AuthForm_deleteButton__3zmWb.AuthForm_red__2u34i {
  background-color: #cc1338;
  color: white; }

.AuthForm_deleteButton__3zmWb:focus {
  box-shadow: none !important;
  border-color: #dbdbdb; }

.AuthForm_deleteButton__3zmWb.AuthForm_red__2u34i:focus {
  color: white !important; }

.AuthForm_deleteButton__3zmWb.AuthForm_red__2u34i:hover, .AuthForm_deleteButton__3zmWb.AuthForm_red__2u34i:active {
  opacity: .9;
  color: white; }

/* MOBILE */
@media (max-width: 430px) {
  .AuthForm_header__1UrAh {
    font-size: 1.3rem; }
  .AuthForm_resetText__TYRrT {
    margin: 15px 15px 0;
    font-size: .9rem; }
  .AuthForm_resetSuccessMessage__3fFeV {
    margin: 10px 15px 0;
    font-size: .85rem; }
  .AuthForm_successIcon__18V8A {
    height: 20px;
    width: 20px; }
  .AuthForm_formFields__27uaj {
    margin-top: 10px;
    padding: 15px 15px; }
  .AuthForm_input__1xLAC {
    font-size: .95rem; }
  .AuthForm_conditions__3CJG1 {
    margin-top: 7px;
    padding-left: 15px;
    font-size: .8rem; }
  .AuthForm_submitButton__1Rf5H {
    margin: 10px auto 0;
    font-size: 1rem; }
  .AuthForm_link__2_zkV {
    margin: 10px 0 0;
    font-size: .85rem; } }

/* GLOBAL VARIABLES */
/*$color-grey-background: #e2e2e2;*/
/*$color-grey-background: #ececec;*/
.Spinner_loader__3qpwL {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #00aec7;
  background: -webkit-linear-gradient(left, #00aec7 10%, rgba(0, 209, 178, 0) 42%);
  background: -webkit-gradient(linear, left top, right top, color-stop(10%, #00aec7), color-stop(42%, rgba(0, 209, 178, 0)));
  background: linear-gradient(to right, #00aec7 10%, rgba(0, 209, 178, 0) 42%);
  position: relative;
  -webkit-animation: Spinner_load3__32wqx 1.4s infinite linear;
  animation: Spinner_load3__32wqx 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  transform: translateZ(0); }

.Spinner_loader__3qpwL:before {
  width: 50%;
  height: 50%;
  background: #00aec7;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: ''; }

.Spinner_loader__3qpwL:after {
  background: #00aec7;
  background: #ffffff;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }

@-webkit-keyframes Spinner_load3__32wqx {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes Spinner_load3__32wqx {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

/* GLOBAL VARIABLES */
/*$color-grey-background: #e2e2e2;*/
/*$color-grey-background: #ececec;*/
.CreateEditInitiativeForm_header__3MUMc {
  display: flex;
  justify-content: center;
  align-items: center; }

.CreateEditInitiativeForm_headerCircle__2Wujt {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-right: 20px;
  box-shadow: 0px 2px 6px 0px #555e63; }

.CreateEditInitiativeForm_headerText__3wpms {
  margin: 0;
  font-size: 1.5rem;
  color: #0A64B8; }

.CreateEditInitiativeForm_errorMessage__30vh2 {
  margin: 20px 20px -5px;
  color: #ff3860;
  font-size: .95rem; }

.CreateEditInitiativeForm_formFields__22BcZ {
  margin-top: 20px;
  padding: 15px 20px;
  border-radius: 5px;
  background-color: #f1f1f1; }

.CreateEditInitiativeForm_input__2UCjV {
  font: inherit; }

.CreateEditInitiativeForm_input__2UCjV:hover {
  border-color: rgba(0, 174, 199, 0.4); }

.CreateEditInitiativeForm_input__2UCjV:focus {
  border-color: #00aec7;
  box-shadow: 0 0 0 0.125em rgba(0, 174, 199, 0.2); }

.CreateEditInitiativeForm_input__2UCjV::-webkit-input-placeholder {
  color: rgba(0, 174, 199, 0.4);
  font-size: 1rem; }

.CreateEditInitiativeForm_input__2UCjV::-moz-placeholder {
  color: rgba(0, 174, 199, 0.4);
  font-size: 1rem; }

.CreateEditInitiativeForm_input__2UCjV::-ms-input-placeholder {
  color: rgba(0, 174, 199, 0.4);
  font-size: 1rem; }

.CreateEditInitiativeForm_input__2UCjV::placeholder {
  color: rgba(0, 174, 199, 0.4);
  font-size: 1rem; }

.CreateEditInitiativeForm_input__2UCjV.CreateEditInitiativeForm_invalid__3r8_l {
  border-color: #ff3860; }

.CreateEditInitiativeForm_textarea__2Y8Tm {
  min-height: 75px; }

.CreateEditInitiativeForm_imagesField__1e6zQ {
  background-color: white;
  border-color: #dbdbdb;
  color: #363636;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  padding: 10px 9px; }

.CreateEditInitiativeForm_imagesText__Grujc {
  margin: 0;
  font-size: .85rem;
  color: #707070;
  line-height: 1.2;
  text-align: justify; }

.CreateEditInitiativeForm_images__1DX0F {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px; }

.CreateEditInitiativeForm_column__2Hu6y {
  width: calc(50% - 5px);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f1f1f1;
  padding: 5px 5px 2px 5px;
  border-radius: 4px; }

.CreateEditInitiativeForm_imageContainer__3pdl2 {
  position: relative;
  padding-top: 66.66%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  opacity: 1; }

.CreateEditInitiativeForm_miniImageIcon__1df6M {
  position: absolute;
  top: 0;
  left: 0;
  height: 50%;
  width: 50%;
  fill: rgba(0, 174, 199, 0.4);
  opacity: 0;
  -webkit-transform-origin: top left;
          transform-origin: top left;
  -webkit-transform: scale(0.1);
          transform: scale(0.1);
  -webkit-transition: opacity .1s ease-out, -webkit-transform .1s ease-out;
  transition: opacity .1s ease-out, -webkit-transform .1s ease-out;
  transition: transform .1s ease-out, opacity .1s ease-out;
  transition: transform .1s ease-out, opacity .1s ease-out, -webkit-transform .1s ease-out; }

.CreateEditInitiativeForm_imageContainer__3pdl2:hover .CreateEditInitiativeForm_miniImageIcon__1df6M, .CreateEditInitiativeForm_imageContainer__3pdl2:focus .CreateEditInitiativeForm_miniImageIcon__1df6M {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1); }

.CreateEditInitiativeForm_iconContainer__2WWp5 {
  position: relative;
  padding-top: 66.66%;
  width: 100%;
  outline: none;
  cursor: pointer;
  background-color: white; }

.CreateEditInitiativeForm_imageIcon__1xECV {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 75%;
  width: 75%;
  fill: rgba(0, 174, 199, 0.4);
  opacity: .8;
  -webkit-transition: opacity .1s ease-out, -webkit-transform .1s ease-out;
  transition: opacity .1s ease-out, -webkit-transform .1s ease-out;
  transition: transform .1s ease-out, opacity .1s ease-out;
  transition: transform .1s ease-out, opacity .1s ease-out, -webkit-transform .1s ease-out; }

.CreateEditInitiativeForm_iconContainer__2WWp5:hover .CreateEditInitiativeForm_imageIcon__1xECV, .CreateEditInitiativeForm_iconContainer__2WWp5:focus .CreateEditInitiativeForm_imageIcon__1xECV {
  opacity: 1;
  -webkit-transform: translate(-50%, -50%) scale(1.1);
          transform: translate(-50%, -50%) scale(1.1); }

.CreateEditInitiativeForm_image__1qgDA {
  object-fit: cover; }

.CreateEditInitiativeForm_imageCaption__1iKX3 {
  margin-top: 2px;
  font-size: .75rem;
  text-transform: uppercase;
  letter-spacing: 13px;
  margin-right: -13px;
  color: rgba(0, 174, 199, 0.4); }

.CreateEditInitiativeForm_inputErrorMessage__16P1f {
  margin: 0 0 5px;
  padding-left: 10px;
  color: #ff3860;
  font-size: .85rem; }

.CreateEditInitiativeForm_imagesErrorMessage__1c0-o {
  margin: 5px 0 0;
  padding-left: 10px;
  color: #ff3860;
  font-size: .85rem; }

.CreateEditInitiativeForm_submitButton__2aDfz {
  display: block;
  width: 40%;
  height: 40px;
  margin: 15px auto 0;
  background-color: #0A64B8;
  color: white;
  font-size: 1.1rem;
  border-radius: 7px; }

.CreateEditInitiativeForm_submitButton__2aDfz:focus {
  box-shadow: none !important;
  border-color: #dbdbdb;
  color: white !important; }

.CreateEditInitiativeForm_submitButton__2aDfz:hover, .CreateEditInitiativeForm_submitButton__2aDfz:active {
  opacity: .9;
  color: white; }

.CreateEditInitiativeForm_submitButton__2aDfz:disabled {
  background-color: #0A64B8;
  opacity: .4; }

/* MOBILE */
@media (max-width: 530px) {
  .CreateEditInitiativeForm_header__3MUMc {
    justify-content: flex-start;
    padding-left: 20px; }
  .CreateEditInitiativeForm_headerCircle__2Wujt {
    margin-right: 10px; }
  .CreateEditInitiativeForm_headerText__3wpms {
    font-size: 1.3rem; }
  .CreateEditInitiativeForm_formFields__22BcZ {
    margin-top: 15px; }
  .CreateEditInitiativeForm_imageCaption__1iKX3 {
    font-size: .65rem;
    letter-spacing: 5px;
    margin-right: -5px; } }

/* GLOBAL VARIABLES */
/*$color-grey-background: #e2e2e2;*/
/*$color-grey-background: #ececec;*/
.DeleteInitiative_header__3jnEZ {
  margin: 0;
  font-size: 1.5rem;
  color: #0A64B8;
  text-align: center; }

.DeleteInitiative_question__2naEz {
  text-align: center;
  font-size: 1.1rem; }

.DeleteInitiative_note__af0UR {
  text-align: center;
  font-size: .8rem;
  color: #ff3860; }

.DeleteInitiative_note__af0UR p {
  margin: 0; }

.DeleteInitiative_buttonsContainer__F_77A {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.DeleteInitiative_button__1vzkS {
  display: block;
  width: 40%;
  height: 40px;
  margin: 15px auto 0;
  font-size: 1.1rem;
  border-radius: 7px; }

.DeleteInitiative_button__1vzkS.DeleteInitiative_delete__2oeeh {
  background-color: #cc1338;
  color: white; }

.DeleteInitiative_button__1vzkS:focus {
  box-shadow: none !important;
  border-color: #dbdbdb; }

.DeleteInitiative_button__1vzkS.DeleteInitiative_delete__2oeeh:focus {
  color: white !important; }

.DeleteInitiative_button__1vzkS.DeleteInitiative_delete__2oeeh:hover, .DeleteInitiative_button__1vzkS.DeleteInitiative_delete__2oeeh:active {
  opacity: .9;
  color: white; }

/* GLOBAL VARIABLES */
/*$color-grey-background: #e2e2e2;*/
/*$color-grey-background: #ececec;*/
/* POPUP */
.InitiativePopup_popup__3Q_ur {
  position: absolute;
  top: 80px;
  left: 10px;
  width: 430px;
  max-width: 95%;
  max-height: calc(100% - 125px);
  z-index: 5;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 0px 7px 0px #231818;
  padding: 15px 25px 10px;
  overflow-y: auto;
  visibility: hidden;
  opacity: 0;
  -webkit-transform-origin: center left;
          transform-origin: center left;
  -webkit-transform: scaleX(0.1);
          transform: scaleX(0.1);
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out; }

.InitiativePopup_popup__3Q_ur.InitiativePopup_show__3dAcD {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scaleX(1);
          transform: scaleX(1); }

.InitiativePopup_closeButton__7oXS2 {
  position: absolute;
  top: 2px;
  right: 2px;
  background-color: white;
  border: 1px solid #00aec7; }

.InitiativePopup_closeButton__7oXS2:focus {
  background-color: white; }

.InitiativePopup_closeButton__7oXS2:hover {
  background-color: rgba(0, 174, 199, 0.1); }

.InitiativePopup_closeButton__7oXS2::before, .InitiativePopup_closeButton__7oXS2::after {
  background-color: #00aec7; }

/* HEADER */
.InitiativePopup_header__OyKcx {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.InitiativePopup_headerCircle__37rPg {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-right: 20px; }

.InitiativePopup_headerText__1L3_9 {
  margin: 0;
  font-size: 1.3rem;
  line-height: 1.1;
  color: #0A64B8; }

.InitiativePopup_subHeader__3A2N1 {
  margin: 4px 0 0 40px;
  line-height: 1; }

.InitiativePopup_teamLabel__36FYs {
  margin: 0 5px 0 0;
  font-size: 1.1rem;
  color: rgba(0, 174, 199, 0.35); }

.InitiativePopup_teamName__3saQL {
  margin: 0;
  font-size: 1.1rem;
  color: #00aec7; }

.InitiativePopup_teamName__3saQL.InitiativePopup_admin__18Qn0:hover {
  cursor: pointer;
  text-decoration: underline; }

/* IMAGES */
.InitiativePopup_imagesRow__3W7ar {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px; }

.InitiativePopup_column__1uQnl {
  width: calc(50% - 5px);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f1f1f1;
  padding: 5px 5px 2px 5px;
  border-radius: 4px; }

.InitiativePopup_imageContainer__1z1P8 {
  padding-top: 66.66%;
  width: 100%;
  outline: none;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center; }

.InitiativePopup_iconContainer__j8kuF {
  position: relative;
  padding-top: 66.66%;
  width: 100%;
  outline: none;
  background-color: white; }

.InitiativePopup_imageIcon__2wj7- {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 75%;
  width: 75%;
  fill: rgba(0, 174, 199, 0.4); }

.InitiativePopup_imageCaption__40Q-A {
  margin-top: 2px;
  font-size: .75rem;
  text-transform: uppercase;
  letter-spacing: 13px;
  margin-right: -13px;
  color: rgba(0, 174, 199, 0.4); }

/* DESCRIPTION */
.InitiativePopup_descriptionRow__3ZYcp {
  margin-top: 10px;
  width: 100%;
  height: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f1f1f1;
  padding: 5px 5px 2px 5px;
  border-radius: 4px; }

.InitiativePopup_label__185hS {
  color: rgba(0, 174, 199, 0.4); }

.InitiativePopup_description__2PGTX {
  width: 100%;
  margin: 5px 0 0 0;
  font-size: .8rem;
  overflow-y: auto; }

.InitiativePopup_description__2PGTX p {
  margin: 0; }

/* MEMBERS & SOCIAL MEDIA */
.InitiativePopup_membersAndSocialMediaRow__2S6xF {
  height: 110px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start; }

.InitiativePopup_members__WySDH {
  width: 100%;
  margin: 5px 0 0 0;
  list-style: none;
  padding-left: 3px;
  overflow-y: auto; }

.InitiativePopup_member__2roR0 {
  margin: 0;
  font-size: .8rem;
  padding-left: 4px;
  position: relative; }

.InitiativePopup_member__2roR0:before {
  content: '\2022';
  position: absolute;
  left: -3px; }

.InitiativePopup_columnWithRows__3dYSC {
  width: calc(50% - 5px);
  height: 100%;
  position: relative; }

.InitiativePopup_socialMedia__2O_mW {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f1f1f1;
  padding: 5px 5px 2px 5px;
  border-radius: 4px; }

.InitiativePopup_socialMediaIcons__2dg0t {
  width: 110px;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.InitiativePopup_twitterIcon__2BT2j {
  display: block;
  margin-left: -8px;
  margin-right: -8px;
  cursor: pointer; }

.InitiativePopup_twitterIcon__2BT2j:hover {
  opacity: .85; }

.InitiativePopup_facebookIcon__14B1l {
  cursor: pointer; }

.InitiativePopup_facebookIcon__14B1l:hover {
  opacity: .85; }

.InitiativePopup_linkIconContainer__-1LmE {
  position: relative;
  height: 21px;
  width: 21px; }

.InitiativePopup_linkIcon__p07I0 {
  height: 100%;
  width: 100%;
  fill: #00aec7;
  cursor: pointer;
  -webkit-transition: fill .1s ease-out;
  transition: fill .1s ease-out; }

.InitiativePopup_linkIcon__p07I0.InitiativePopup_copied__GPEYN {
  fill: #008092; }

.InitiativePopup_linkIcon__p07I0:hover {
  opacity: .85; }

.InitiativePopup_linkCopiedMessage__2xU0J {
  position: absolute;
  top: 110%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  font-size: .65rem;
  border-radius: 5px;
  padding: 1px 2px;
  background-color: #4a4c4c;
  color: white;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: visibility .1s ease-out, opacity .1s ease-out;
  transition: visibility .1s ease-out, opacity .1s ease-out; }

.InitiativePopup_linkIcon__p07I0.InitiativePopup_copied__GPEYN + .InitiativePopup_linkCopiedMessage__2xU0J {
  visibility: visible;
  opacity: 1; }

.InitiativePopup_hiddenLinkText__3L690 {
  position: absolute;
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1; }

.InitiativePopup_buttonsContainer__2yMOj {
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: space-between; }

.InitiativePopup_button__1H4_X {
  width: 45%;
  background-color: #0A64B8;
  color: white;
  font-size: .9rem;
  border: none; }

.InitiativePopup_button__1H4_X:hover, .InitiativePopup_button__1H4_X:focus {
  color: white;
  opacity: .9; }

.InitiativePopup_modalCloseButton__Q6QL3 {
  top: 0;
  right: 10px; }

.InitiativePopup_modalBackground__3TpLw {
  background-color: rgba(0, 0, 0, 0.9); }

.InitiativePopup_modalContent__Z4Ljl {
  height: 100%;
  max-height: 100% !important;
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: hidden !important; }

.InitiativePopup_fullScreenImage__2l-iM {
  max-height: 100%;
  max-width: 100%; }

/* Style Popup Scrollbar (Webkit only and only for desktop) */
@media (min-width: 1024px) {
  .InitiativePopup_popup__3Q_ur::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: transparent;
    border-radius: 10px; }
  .InitiativePopup_popup__3Q_ur::-webkit-scrollbar {
    width: 11px;
    border-radius: 10px;
    background-color: transparent; }
  .InitiativePopup_popup__3Q_ur::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 2px solid transparent;
    background-clip: padding-box;
    background-color: rgba(0, 174, 199, 0.4); } }

/* Style Members & Description Scrollbar (Webkit only and only for desktop) */
@media (min-width: 1024px) {
  .InitiativePopup_members__WySDH::-webkit-scrollbar-track, .InitiativePopup_description__2PGTX::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: transparent;
    border-radius: 10px; }
  .InitiativePopup_members__WySDH::-webkit-scrollbar, .InitiativePopup_description__2PGTX::-webkit-scrollbar {
    width: 9px;
    border-radius: 10px;
    background-color: transparent; }
  .InitiativePopup_members__WySDH::-webkit-scrollbar-thumb, .InitiativePopup_description__2PGTX::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 1px solid transparent;
    background-clip: padding-box;
    background-color: rgba(0, 174, 199, 0.4); } }

/* MOBILE */
@media (max-width: 650px) {
  .InitiativePopup_popup__3Q_ur {
    top: 70px;
    left: 5px;
    width: 270px;
    max-width: calc(100% - 70px);
    max-height: 300px;
    padding: 10px 10px 5px 10px; }
  .InitiativePopup_headerCircle__37rPg {
    height: 15px;
    width: 15px;
    margin-right: 10px; }
  .InitiativePopup_headerText__1L3_9 {
    font-size: .9rem;
    padding-right: 15px; }
  .InitiativePopup_subHeader__3A2N1 {
    margin: 0 0 0 25px;
    line-height: .85; }
  .InitiativePopup_teamLabel__36FYs {
    font-size: .8rem; }
  .InitiativePopup_teamName__3saQL {
    font-size: .8rem; }
  .InitiativePopup_imagesRow__3W7ar {
    margin-top: 3px; }
  .InitiativePopup_imageCaption__40Q-A {
    margin-top: 2px;
    font-size: .55rem;
    letter-spacing: 5px;
    margin-right: -5px; }
  .InitiativePopup_descriptionRow__3ZYcp {
    margin-top: 3px;
    width: 100%;
    height: 55px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f1f1f1;
    padding: 1px 5px;
    border-radius: 4px; }
  .InitiativePopup_label__185hS {
    font-size: .7rem; }
  .InitiativePopup_description__2PGTX {
    width: 100%;
    margin: 0;
    font-size: .7rem;
    overflow-y: auto; }
  .InitiativePopup_membersAndSocialMediaRow__2S6xF {
    height: 65px;
    margin-top: 3px; }
  .InitiativePopup_membersColumn__26XXg {
    padding: 1px 5px; }
  .InitiativePopup_members__WySDH {
    margin: 0; }
  .InitiativePopup_member__2roR0 {
    font-size: .7rem;
    padding-left: 3px; }
  .InitiativePopup_socialMedia__2O_mW {
    padding: 1px 5px; }
  .InitiativePopup_socialMediaIcons__2dg0t {
    margin-top: -5px;
    margin-bottom: -3px;
    width: 70px; }
  .InitiativePopup_twitterIcon__2BT2j {
    height: 34px;
    width: 34px; }
  .InitiativePopup_facebookIcon__14B1l {
    height: 18px;
    width: 18px; }
  .InitiativePopup_linkIconContainer__-1LmE {
    height: 18px;
    width: 18px; }
  .InitiativePopup_linkCopiedMessage__2xU0J {
    top: 100%;
    font-size: .55rem;
    padding: 0 2px; }
  .InitiativePopup_button__1H4_X {
    width: 45%;
    height: 20px;
    line-height: 20px;
    font-size: .7rem;
    padding: 0; }
  .InitiativePopup_modalContent__Z4Ljl {
    padding: 10px; } }

@media (max-height: 370px) {
  .InitiativePopup_popup__3Q_ur {
    top: 65px;
    max-height: calc(100% - 70px); } }

/* GLOBAL VARIABLES */
/*$color-grey-background: #e2e2e2;*/
/*$color-grey-background: #ececec;*/
.TeamsList_teamsListContainer__3XLBH {
  position: absolute;
  top: 80px;
  left: 10px;
  width: 250px;
  max-height: 400px;
  overflow-y: auto;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 7px 0px #231818;
  padding: 5px 10px;
  visibility: hidden;
  opacity: 0;
  -webkit-transform-origin: top center;
          transform-origin: top center;
  -webkit-transform: scaleY(0.1);
          transform: scaleY(0.1);
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out; }

.TeamsList_teamsListContainer__3XLBH.TeamsList_show__1_8KX {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scaleY(1);
          transform: scaleY(1); }

.TeamsList_closeButton__25bAl {
  position: absolute;
  top: 2px;
  right: 2px;
  background-color: white;
  border: 1px solid #00aec7; }

.TeamsList_closeButton__25bAl:focus {
  background-color: white; }

.TeamsList_closeButton__25bAl:hover {
  background-color: rgba(0, 174, 199, 0.1); }

.TeamsList_closeButton__25bAl::before, .TeamsList_closeButton__25bAl::after {
  background-color: #00aec7; }

.TeamsList_header__9YlX0 {
  margin: 0 0 10px 30px;
  font-size: 1rem;
  color: #00aec7;
  font-weight: normal;
  letter-spacing: 10px;
  text-transform: uppercase; }

.TeamsList_teamsList__SzWBC {
  list-style: none;
  margin: 0;
  padding: 0; }

.TeamsList_teamRow__3MlFz {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  padding: 0 3px; }

.TeamsList_teamRow__3MlFz:not(:nth-last-child(2)):after {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  content: '';
  width: 100%;
  border-bottom: 1px solid #00aec7;
  text-align: center; }

.TeamsList_checkboxContainer__YS3Ud {
  position: relative;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  -webkit-transition: background-color .1s ease-out;
  transition: background-color .1s ease-out; }

.TeamsList_teamRow__3MlFz.TeamsList_checked__1AQiu .TeamsList_checkboxContainer__YS3Ud {
  background-color: #00aec7; }

.TeamsList_checkbox__oJrQz {
  position: absolute;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  height: 100%;
  border: 1px solid #00aec7;
  border-radius: 2px;
  z-index: 10;
  margin: 0;
  cursor: pointer;
  outline: none;
  background-color: transparent; }

.TeamsList_checkmark__2L4Ka {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 0;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 90%;
  width: 90%;
  fill: white;
  opacity: 0;
  -webkit-transition: opacity .1s ease-out;
  transition: opacity .1s ease-out; }

.TeamsList_teamRow__3MlFz.TeamsList_checked__1AQiu .TeamsList_checkmark__2L4Ka {
  opacity: 1; }

.TeamsList_teamName__VSRsZ {
  width: 75%;
  color: #002e5d;
  font-size: .95rem;
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: .5;
  -webkit-transition: opacity .1s ease-out;
  transition: opacity .1s ease-out; }

.TeamsList_teamName__VSRsZ.TeamsList_admin__193HJ:hover {
  cursor: pointer;
  text-decoration: underline; }

.TeamsList_teamRow__3MlFz.TeamsList_checked__1AQiu .TeamsList_teamName__VSRsZ {
  opacity: 1; }

.TeamsList_teamCircle__X8hJW {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  box-shadow: 0px 2px 2px 0px #555e63;
  opacity: .5;
  -webkit-transition: opacity .1s ease-out;
  transition: opacity .1s ease-out; }

.TeamsList_teamRow__3MlFz.TeamsList_checked__1AQiu .TeamsList_teamCircle__X8hJW {
  opacity: 1; }

.TeamsList_selectAllNone__2eid7 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  margin: 10px 0 0 40px; }

.TeamsList_selectAllNone__2eid7 span {
  font-size: .8rem;
  font-style: italic;
  color: #00aec7;
  cursor: pointer;
  opacity: 1; }

.TeamsList_selectAllNone__2eid7 span:hover {
  opacity: .7; }

/* Style Popup Scrollbar (Webkit only and only for desktop) */
@media (min-width: 1024px) {
  .TeamsList_teamsListContainer__3XLBH::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: transparent;
    border-radius: 10px; }
  .TeamsList_teamsListContainer__3XLBH::-webkit-scrollbar {
    width: 11px;
    border-radius: 10px;
    background-color: transparent; }
  .TeamsList_teamsListContainer__3XLBH::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 2px solid transparent;
    background-clip: padding-box;
    background-color: rgba(0, 174, 199, 0.4); } }

/* MOBILE */
@media (max-width: 650px) {
  .TeamsList_teamsListContainer__3XLBH {
    left: 5px;
    width: 200px;
    max-height: 157px;
    padding: 5px 5px; }
  .TeamsList_header__9YlX0 {
    margin: 0 0 10px 25px;
    font-size: .9rem;
    letter-spacing: 8px; }
  .TeamsList_teamName__VSRsZ {
    font-size: .9rem; }
  .TeamsList_checkboxContainer__YS3Ud {
    width: 14px;
    height: 14px; }
  .TeamsList_selectAllNone__2eid7 {
    margin: 10px 0 0 35px; } }

/* GLOBAL VARIABLES */
/*$color-grey-background: #e2e2e2;*/
/*$color-grey-background: #ececec;*/
.TeamAdmin_loadingBlankPage__1gJcK {
  display: flex;
  justify-content: center;
  align-items: center; }

.TeamAdmin_header__3fF1y {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px; }

.TeamAdmin_headerCircle__2Z9Iu {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-right: 20px;
  box-shadow: 0px 2px 6px 0px #555e63; }

.TeamAdmin_headerText__1gYbg {
  margin: 0;
  font-size: 1.5rem;
  color: #0A64B8; }

.TeamAdmin_field__2VzSA {
  font-size: 1.1rem;
  margin-bottom: 10px; }

.TeamAdmin_label__1WKwn {
  font-size: 1rem;
  margin: 0 5px 0 0;
  color: #00aec7; }

.TeamAdmin_membersList__3of1s {
  margin: 0;
  padding: 0 0 0 15px;
  list-style: none; }

.TeamAdmin_deleteButton__1g0aO {
  display: block;
  margin: 25px auto 0;
  padding: 10px 20px;
  font-size: 1.1rem;
  border-radius: 7px;
  background-color: #cc1338;
  color: white; }

.TeamAdmin_deleteButton__1g0aO:focus {
  outline: none; }

.TeamAdmin_deleteButton__1g0aO:hover, .TeamAdmin_deleteButton__1g0aO:active {
  cursor: pointer;
  opacity: .9;
  color: white; }

/* GLOBAL VARIABLES */
/*$color-grey-background: #e2e2e2;*/
/*$color-grey-background: #ececec;*/
.Faq_header__2xvTs {
  margin: 0 0 20px;
  text-align: center;
  font-size: 1.5rem;
  color: #0A64B8; }

.Faq_faqBlock__2NWL5 {
  margin-bottom: 20px; }

.Faq_question__1RAmc {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer; }

.Faq_questionText__2m15h {
  width: 90%;
  margin: 0;
  font-weight: bold;
  color: #00aec7; }

.Faq_questionIcon__3ZOFL {
  height: 20px;
  width: 20px;
  fill: #00aec7; }

.Faq_answer__2zrOa {
  margin: 0 0 10px;
  text-align: justify; }

/* GLOBAL VARIABLES */
/*$color-grey-background: #e2e2e2;*/
/*$color-grey-background: #ececec;*/
.Notification_notification__2b54h {
  position: absolute;
  top: 63px;
  left: 50%;
  width: 450px;
  height: 60px;
  z-index: 50;
  background-color: rgba(241, 253, 255, 0.85);
  box-shadow: 0px 0px 7px 0px #231818;
  border-radius: 8px;
  padding: 5px 15px;
  overflow: hidden;
  display: flex;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  cursor: pointer;
  -webkit-transform-origin: top center;
          transform-origin: top center;
  -webkit-transform: scaleY(0.1) translateX(-50%);
          transform: scaleY(0.1) translateX(-50%);
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out; }

.Notification_notification__2b54h.Notification_show__QMLp9 {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scaleX(1) translateX(-50%);
          transform: scaleX(1) translateX(-50%); }

.Notification_loginCircle__zt1XY {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-right: 15px;
  box-shadow: 0px 2px 2px 0px #555e63; }

.Notification_loginText__BFEKQ {
  height: 100%;
  margin: 0;
  color: #00aec7;
  font-weight: bold; }

.Notification_adminLogin__1GQlU {
  margin: 0;
  color: #00aec7;
  font-weight: bold; }

.Notification_successMessage__3eagR {
  color: #00aec7;
  display: flex;
  align-items: center; }

.Notification_successIcon__2jNtq {
  fill: #00aec7;
  margin-right: 7px; }

.Notification_errorMessage__IlPiC {
  margin: 0;
  color: #ff3860;
  font-size: .95rem; }

.Notification_errorIcon__3cLWu {
  height: 35px;
  width: 35px;
  fill: #ff3860;
  margin-right: 10px; }

@media (max-width: 640px) {
  .Notification_notification__2b54h {
    left: 10px;
    max-width: calc(100% - 60px);
    height: 50px;
    font-size: .95rem;
    -webkit-transform: scaleY(0.1);
            transform: scaleY(0.1); }
  .Notification_notification__2b54h.Notification_show__QMLp9 {
    visibility: visible;
    opacity: 1;
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }


@import '../../_variables.scss';

.header {
	margin: 0 0 20px;
	text-align: center;
	font-size: 1.5rem;
	color: $color-medium-blue;
}

.faqBlock {
	margin-bottom: 20px;
}

.question {
	margin: 10px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
}

.questionText {
	width: 90%;
	margin: 0;
	font-weight: bold;
	color: $color-uma-light-blue;
}

.questionIcon {
	height: 20px;
	width: 20px;
	fill: $color-uma-light-blue;
}

.answer {
	margin: 0 0 10px;
	text-align: justify;
}
/* GLOBAL VARIABLES */
$color-uma-orange: #ff8326;
$color-uma-grey: #888b8d;
$color-uma-dark-blue: #002e5d;
$color-uma-light-blue: #00aec7;
$color-medium-blue: #0A64B8;
/*$color-grey-background: #e2e2e2;*/
/*$color-grey-background: #ececec;*/
$color-grey-background: #f1f1f1;
$color-form-light-blue: rgba(0, 174, 199, 0.4);
$color-danger: #ff3860;
$color-success: #23d160;
$color-delete: #cc1338;

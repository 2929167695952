@import '../../_variables.scss';

/* POPUP */
.popup {
  position: absolute;
  top: 80px;
  left: 10px;
  width: 430px;
  max-width: 95%;
  max-height: calc(100% - 125px);
	z-index: 5;
	background-color: white;
	border-radius: 8px;
	box-shadow: 0px 0px 7px 0px #231818;
	padding: 15px 25px 10px;
	overflow-y: auto;
	visibility: hidden;
	opacity: 0;
	transform-origin: center left;
	transform: scaleX(.1);
	transition: all .2s ease-out;
}

.popup.show {
	visibility: visible;
	opacity: 1;
	transform: scaleX(1);
}

.closeButton {
	position: absolute;
	top: 2px;
	right: 2px;
	background-color: white;
	border: 1px solid $color-uma-light-blue;
}

.closeButton:focus {
	background-color: white;
}

.closeButton:hover {
	background-color: rgba(0, 174, 199, .1);
}

.closeButton::before, .closeButton::after {
	background-color: $color-uma-light-blue;
}

/* HEADER */
.header {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.headerCircle {
	height: 20px;
	width: 20px;
	border-radius: 50%;
	margin-right: 20px;
}

.headerText {
	margin: 0;
	font-size: 1.3rem;
	line-height: 1.1;
	color: $color-medium-blue;
}

.subHeader {
	margin: 4px 0 0 40px;
	line-height: 1;
}

.teamLabel {
	margin: 0 5px 0 0;
	font-size: 1.1rem;
	color: rgba(0, 174, 199, 0.35);	
}

.teamName {
	margin: 0;
	font-size: 1.1rem;
	color: $color-uma-light-blue;	
}

.teamName.admin:hover {
	cursor: pointer;
	text-decoration: underline;
}

/* IMAGES */
.imagesRow {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-top: 10px;
}

.column {
	width: calc(50% - 5px);
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: $color-grey-background;
	padding: 5px 5px 2px 5px;
	border-radius: 4px;
}

.imageContainer {
	padding-top: 66.66%;
	width: 100%;
	outline: none;
	cursor: pointer;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center center;
}

.iconContainer {
	position: relative;
	padding-top: 66.66%;
	width: 100%;
	outline: none;
	background-color: white;
}

.imageIcon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 75%;
	width: 75%;
	fill: $color-form-light-blue;
}

.imageCaption {
	margin-top: 2px;
	font-size: .75rem;
	text-transform: uppercase;
	letter-spacing: 13px;
	margin-right: -13px;
	color: $color-form-light-blue;
}

/* DESCRIPTION */
.descriptionRow {
	margin-top: 10px;
	width: 100%;
	height: 90px;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: $color-grey-background;
	padding: 5px 5px 2px 5px;
	border-radius: 4px;		
}

.label {
	color: $color-form-light-blue;	
}

.description {
	width: 100%;
	margin: 5px 0 0 0;
	font-size: .8rem;
	overflow-y: auto;
}

.description p {
	margin: 0;
}

/* MEMBERS & SOCIAL MEDIA */
.membersAndSocialMediaRow {
	height: 110px;
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.members {
	width: 100%;
	margin: 5px 0 0 0;
	list-style: none;
	padding-left: 3px;
	overflow-y: auto;
}

.member {
	margin: 0;
	font-size: .8rem;
	padding-left: 4px;
	position: relative;
}

.member:before {
	content: '\2022';
	position: absolute;
	left: -3px;
}

.columnWithRows {
	width: calc(50% - 5px);
	height: 100%;
	position: relative;
}

.socialMedia {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: $color-grey-background;
	padding: 5px 5px 2px 5px;
	border-radius: 4px;
}

.socialMediaIcons {
	width: 110px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.twitterIcon {
	display: block;
	margin-left: -8px;
	margin-right: -8px;
	cursor: pointer;
}

.twitterIcon:hover {
	opacity: .85;
}

.facebookIcon {
	cursor: pointer;
}

.facebookIcon:hover {
	opacity: .85;
}

.linkIconContainer {
	position: relative;
	height: 21px;
	width: 21px;
}

.linkIcon {
	height: 100%;
	width: 100%;
	fill: $color-uma-light-blue;
	cursor: pointer;
	transition: fill .1s ease-out;
}

.linkIcon.copied {
	fill: #008092;
}

.linkIcon:hover {
	opacity: .85;
}

.linkCopiedMessage {
	position: absolute;
	top: 110%;
	left: 50%;
	transform: translateX(-50%);
	font-size: .65rem;
	border-radius: 5px;
	padding: 1px 2px;
	background-color: #4a4c4c;
	color: white;
	white-space: nowrap;
	visibility: hidden;
	opacity: 0;
	transition: visibility .1s ease-out, opacity .1s ease-out;
}

.linkIcon.copied + .linkCopiedMessage {
	visibility: visible;
	opacity: 1;
}

.hiddenLinkText {
	position: absolute;
	height: 0;
	width: 0;
	opacity: 0;
	z-index: -1
}

.buttonsContainer {
	position: absolute;
	width: 100%;
	bottom: 0;
	display: flex;
	justify-content: space-between;
}

.button {
	width: 45%;
	background-color: $color-medium-blue;
	color: white;
	font-size: .9rem;
	border: none;
}

.button:hover, .button:focus {
	color: white;
	opacity: .9;
}

.modalCloseButton {
	top: 0;
	right: 10px;
}

.modalBackground {
	background-color: rgba(0, 0 , 0, .9);
}

.modalContent {
	height: 100%;
	max-height: 100% !important;
	width: 100%;
	padding: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow-y: hidden !important;
}

.fullScreenImage {
	max-height: 100%;
	max-width: 100%;	
}

/* Style Popup Scrollbar (Webkit only and only for desktop) */
@media (min-width: 1024px) {
	.popup::-webkit-scrollbar-track	{
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
		background-color: transparent;
		border-radius: 10px;
	}
	.popup::-webkit-scrollbar	{
		width: 11px;
		border-radius: 10px;
		background-color: transparent;
	}
	.popup::-webkit-scrollbar-thumb	{
		border-radius: 10px;
		border: 2px solid transparent;
		background-clip: padding-box;
		background-color: $color-form-light-blue;
	}
}

/* Style Members & Description Scrollbar (Webkit only and only for desktop) */
@media (min-width: 1024px) {
	.members::-webkit-scrollbar-track, .description::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
		background-color: transparent;
		border-radius: 10px;
	}
	.members::-webkit-scrollbar, .description::-webkit-scrollbar {
		width: 9px;
		border-radius: 10px;
		background-color: transparent;
	}
	.members::-webkit-scrollbar-thumb, .description::-webkit-scrollbar-thumb {
		border-radius: 10px;
		border: 1px solid transparent;
		background-clip: padding-box;
		background-color: $color-form-light-blue;
	}
}

/* MOBILE */
@media (max-width: 650px) {
	.popup {
	  top: 70px;
	  left: 5px;
	  width: 270px;
	  max-width: calc(100% - 70px);
	  max-height: 300px;
		padding: 10px 10px 5px 10px;
	}
	.headerCircle {
		height: 15px;
		width: 15px;
		margin-right: 10px;
	}
	.headerText {
		font-size: .9rem;
		padding-right: 15px;
	}
	.subHeader {
		margin: 0 0 0 25px;
		line-height: .85;
	}
	.teamLabel {
		font-size: .8rem;
	}
	.teamName {
		font-size: .8rem;
	}
	.imagesRow {
		margin-top: 3px;
	}
	.imageCaption {
		margin-top: 2px;
		font-size: .55rem;
		letter-spacing: 5px;
		margin-right: -5px;
	}
	.descriptionRow {
		margin-top: 3px;
		width: 100%;
		height: 55px;
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: $color-grey-background;
		padding: 1px 5px;
		border-radius: 4px;		
	}
	.label {
		font-size: .7rem;
	}
	.description {
		width: 100%;
		margin: 0;
		font-size: .7rem;
		overflow-y: auto;
	}
	.membersAndSocialMediaRow {
		height: 65px;
		margin-top: 3px;
	}
	.membersColumn {
		padding: 1px 5px;
	}
	.members {
		margin: 0;
	}
	.member {
		font-size: .7rem;
		padding-left: 3px;
	}
	.socialMedia {
		padding: 1px 5px;
	}
	.socialMediaIcons {
		margin-top: -5px;
		margin-bottom: -3px;
		width: 70px;
	}
	.twitterIcon {
		height: 34px;
		width: 34px;
	}
	.facebookIcon {
		height: 18px;
		width: 18px;
	}
	.linkIconContainer {
		height: 18px;
		width: 18px;
	}
	.linkCopiedMessage {
		top: 100%;
		font-size: .55rem;
		padding: 0 2px;
	}
	.button {
		width: 45%;
		height: 20px;
		line-height: 20px;
		font-size: .7rem;
		padding: 0;
	}
	.modalContent {
		padding: 10px;
	}
}
@media (max-height: 370px) {
	.popup {
	  top: 65px;
	  max-height: calc(100% - 70px);
	}
}
@import '../../_variables.scss';

.header {
	margin: 0;
	font-size: 1.5rem;
	color: $color-medium-blue;
	text-align: center;
}

.question {
	text-align: center;
	font-size: 1.1rem;
}

.note {
	text-align: center;
	font-size: .8rem;
	color: $color-danger;
}

.note p {
	margin: 0;
}

.buttonsContainer {
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.button {
	display: block;
	width: 40%;
	height: 40px;
	margin: 15px auto 0;
	font-size: 1.1rem;
	border-radius: 7px;
}

.button.delete {
	background-color: $color-delete;
	color: white;
}

.button:focus {
 	box-shadow: none !important;
 	border-color: #dbdbdb;
}

.button.delete:focus {
 	color: white !important;	
}

.button.delete:hover, .button.delete:active {
	opacity: .9;
	color: white;
}
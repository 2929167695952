@import '../../_variables.scss';

.teamsListContainer {
  position: absolute;
  top: 80px;
  left: 10px;
  width: 250px;
  max-height: 400px;
  overflow-y: auto;
	background-color: white;
	border-radius: 5px;
	box-shadow: 0px 0px 7px 0px #231818;
	padding: 5px 10px;
	visibility: hidden;
	opacity: 0;
	transform-origin: top center;
	transform: scaleY(.1);
	transition: all .2s ease-out;
}

.teamsListContainer.show {
	visibility: visible;
	opacity: 1;
	transform: scaleY(1);
}

.closeButton {
	position: absolute;
	top: 2px;
	right: 2px;
	background-color: white;
	border: 1px solid $color-uma-light-blue;
}

.closeButton:focus {
	background-color: white;
}

.closeButton:hover {
	background-color: rgba(0, 174, 199, .1);
}

.closeButton::before, .closeButton::after {
	background-color: $color-uma-light-blue;
}

.header {
	margin: 0 0 10px 30px;
	font-size: 1rem;
	color: $color-uma-light-blue;
	font-weight: normal;
	letter-spacing: 10px;
	text-transform: uppercase;
}

.teamsList {
	list-style: none;
	margin: 0;
	padding: 0;
}

.teamRow {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 8px;
	padding: 0 3px;
}

.teamRow:not(:nth-last-child(2)):after {
	position: absolute;
	top: calc(100% + 4px);
	left: 0;
	content: '';
	width: 100%;
	border-bottom: 1px solid $color-uma-light-blue;
	text-align: center;
}

.checkboxContainer {
	position:relative;
	width: 14px;
  height: 14px;
  border-radius: 2px;
  transition: background-color .1s ease-out;
}

.teamRow.checked .checkboxContainer{
	background-color: $color-uma-light-blue;
}

.checkbox {
	position: absolute;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  height: 100%;
  border: 1px solid $color-uma-light-blue;
  border-radius: 2px;
  z-index: 10;
  margin: 0;
  cursor: pointer;
  outline: none;
  background-color: transparent;
}

.checkmark {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 0;
	transform: translate(-50%, -50%);
	height: 90%;
	width: 90%;
	fill: white;
	opacity: 0;
	transition: opacity .1s ease-out;
}

.teamRow.checked .checkmark {
	opacity: 1;
}

.teamName {
	width: 75%;
	color: $color-uma-dark-blue;
	font-size: .95rem;
	transform: translateY(1px);
	overflow: hidden;
  white-space:nowrap;
	text-overflow: ellipsis;
	opacity: .5;
	transition: opacity .1s ease-out;
}

.teamName.admin:hover {
	cursor: pointer;
	text-decoration: underline;
}

.teamRow.checked .teamName {
	opacity: 1;
}

.teamCircle {
	height: 15px;
	width: 15px;
	border-radius: 50%;
	box-shadow: 0px 2px 2px 0px #555e63;
	opacity: .5;
	transition: opacity .1s ease-out;
}

.teamRow.checked .teamCircle {
	opacity: 1;
}

.selectAllNone {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 60%;
	margin: 10px 0 0 40px;
}

.selectAllNone span {
	font-size: .8rem;
	font-style: italic;
	color: $color-uma-light-blue;
	cursor: pointer;
	opacity: 1;
}

.selectAllNone span:hover {
	opacity: .7;
}

/* Style Popup Scrollbar (Webkit only and only for desktop) */
@media (min-width: 1024px) {
	.teamsListContainer::-webkit-scrollbar-track	{
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
		background-color: transparent;
		border-radius: 10px;
	}
	.teamsListContainer::-webkit-scrollbar	{
		width: 11px;
		border-radius: 10px;
		background-color: transparent;
	}
	.teamsListContainer::-webkit-scrollbar-thumb	{
		border-radius: 10px;
		border: 2px solid transparent;
		background-clip: padding-box;
		background-color: $color-form-light-blue;
	}
}

/* MOBILE */
@media (max-width: 650px) {
	.teamsListContainer {
	  left: 5px;
	  width: 200px;
	  max-height: 157px;
	  padding: 5px 5px;
	}
	.header {
		margin: 0 0 10px 25px;
		font-size: .9rem;
		letter-spacing: 8px;
	}
	.teamName {
		font-size: .9rem;
	}
	.checkboxContainer {
		width: 14px;
	  height: 14px;
	}
	.selectAllNone {
		margin: 10px 0 0 35px;
	}
}
@import '../../_variables.scss';

.container {
	position: absolute;
	bottom: 35px;
	right: 12px;
}

.newInitiativeButton {
	position: relative;
	height: 60px;
	width: 60px;
	border-radius: 50%;
	background-color: white;
	cursor: pointer;
	outline: none;
	border: 2px solid $color-uma-light-blue;
}

.newInitiativeButton:hover {
	box-shadow: 0px 0px 10px 0px #ffeab0;
}

.newInitiativeButton.selected {
	background-color: $color-uma-light-blue;
	box-shadow: 0px 0px 20px 5px #ffeab0;
}

.icon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 80%;
	fill: $color-uma-light-blue;
}

.newInitiativeButton.selected .icon {
	fill: white;
}

/* MOBILE */
@media (max-width: 640px) {
	.container {
		right: 5px;
		bottom: 30px;
	}
	.newInitiativeButton {
		height: 50px;
		width: 50px;
	}
}
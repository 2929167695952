@import '../../_variables.scss';

.loadingBlankPage {
	display: flex;
	justify-content: center;
	align-items: center;
}

.header {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 20px;
}

.headerCircle {
	height: 20px;
	width: 20px;
	border-radius: 50%;
	margin-right: 20px;
	box-shadow: 0px 2px 6px 0px #555e63;
}

.headerText {
	margin: 0;
	font-size: 1.5rem;
	color: $color-medium-blue;
}

.field {
	font-size: 1.1rem;
	margin-bottom: 10px;
}

.label {
	font-size: 1rem;
	margin: 0 5px 0 0;
	color: $color-uma-light-blue;
}

.membersList {
	margin: 0;
	padding: 0 0 0 15px;
	list-style: none;
}

.deleteButton {
	display: block;
	margin: 25px auto 0;
	padding: 10px 20px;
	font-size: 1.1rem;
	border-radius: 7px;
	background-color: $color-delete;
	color: white;
}

.deleteButton:focus {
 	outline: none;
}

.deleteButton:hover, .deleteButton:active {
	cursor: pointer;
	opacity: .9;
	color: white;
}